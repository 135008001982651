import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

import socialImage from "../lingo-champion-social-sharing.png";

function ImportDuolingoVocabulary() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>Import Duolingo Vocabulary List - {metaData.appName}</title>
        <meta
          name="description"
          key="description"
          content="How to find and import your Duolingo vocabulary list to Lingo Champion."
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "1% 5%" }}>
        <h1 className="mt-4">
          Import Your Duolingo Vocabulary List to Lingo Champion
        </h1>

        <div className="mt-4">
          <p></p>
        </div>

        <div className="mt-4">
          <h2>How to find your Duolingo vocabulary</h2>
          <p>
            Duolingo used to have a vocabulary list right inside their app. This
            has now been removed, so, the only way to get your vocabulary is to
            use third party apps.
          </p>
          <p>
            Use the{" "}
            <a
              href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff"
              target="_blank"
            >
              Lingo Champion browser extension
            </a>{" "}
            to find your Duolingo vocabulary list. It has both free and paid
            plans. The output contains the words you have learned and their
            translations.
          </p>
        </div>

        <div className="mt-4">
          <h2>How to import the words to Lingo Champion</h2>
          <p>
            <ol>
              <li>Install the <a
              href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff"
              target="_blank"
            >browser extension</a></li>
              <li>
                Navigate to{" "}
                <a href="https://www.duolingo.com/practice-hub/words">
                  the words page on Duolingo
                </a>
              </li>
              <li>
                Click on the "Import words" button on top of the page
              </li>
              <li>
                Use the words however you please:
                <ul><li>Download the words</li>
                  <li>Practice the words with flashcards in Lingo Champion</li>
                  <li>Translate parts of websites using the words as you browse (with the browser extension)</li>
                <li>Use the words to filter out texts (news, stories, books) for your level in Lingo Champion</li></ul>
              </li>
            </ol>
          </p>
          <p><i>Note that Duolingo does not provide words for every single course. If there are no words on the page, it means they don't provide it.</i></p>
        </div>
      </Container>

      <Container style={{ padding: "3% 3%" }}>
        {/* <LearnDuolingoVocabularyMainCTA />
        <HowItWorks />
        <Customize />
        <Benefits />
        <FounderQuote />
        <DownloadExtension /> */}
      </Container>
    </>
  );
}

export default ImportDuolingoVocabulary;
