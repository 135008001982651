// lingo-champion-new/client/src/Components/LibraryTabImported.js
import React, { useEffect, useState } from "react";
import { Alert, Button, Row, Nav } from "react-bootstrap";
import { Magic } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import LibraryCard from "./LibraryCard";
import axios from "axios";
import { useTranslation } from "react-i18next";
import SongCard from "./SongCard";
import CollectionCard from "./CollectionCard";
import Spinner from "react-bootstrap/Spinner";

const LibraryTabImported = ({
  checkIfArticleIsBookmarked,
  languageLearningCode,
}) => {
  const { t } = useTranslation();
  const [importedArticles, setImportedArticles] = useState([]);
  const [importedArticlesAreLoading, setImportedArticlesAreLoading] =
    useState(false);
  const [activeTab, setActiveTab] = useState("texts");
  const [importedSongs, setImportedSongs] = useState([]);
  const [importedSongsAreLoading, setImportedSongsAreLoading] = useState(false);
  const [importedCollections, setImportedCollections] = useState([]);
  const [importedCollectionsAreLoading, setImportedCollectionsAreLoading] =
    useState(false);

  const getImportedArticles = async () => {
    setImportedArticlesAreLoading(true);
    try {
      const response = await axios.get(`/api/user/articles/imported`, {
        params: {
          language: languageLearningCode,
          type: "texts",
        },
      });
      setImportedArticles(response.data.articles);
    } catch (error) {
      console.error("Error getting imported articles:", error);
    } finally {
      setImportedArticlesAreLoading(false);
    }
  };

  const getImportedSongs = async () => {
    setImportedSongsAreLoading(true);
    try {
      const response = await axios.get(`/api/user/songs`, {
        params: {
          language: languageLearningCode,
        },
      });
      setImportedSongs(response.data.songs);
    } catch (error) {
      console.error("Error getting imported songs:", error);
    } finally {
      setImportedSongsAreLoading(false);
    }
  };

  const getImportedCollections = async () => {
    setImportedCollectionsAreLoading(true);
    try {
      const response = await axios.get(`/api/user/collections`, {
        params: {
          language: languageLearningCode,
        },
      });
      setImportedCollections(response.data.collections);
    } catch (error) {
      console.error("Error getting imported collections:", error);
    } finally {
      setImportedCollectionsAreLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "texts") {
      getImportedArticles();
    } else if (activeTab === "songs") {
      getImportedSongs();
    } else if (activeTab === "collections") {
      getImportedCollections();
    }
  }, [activeTab]);

  return (
    <>
      <Nav variant="pills" className="mb-3">
        <Nav.Item>
          <Nav.Link
            eventKey="texts"
            active={activeTab === "texts"}
            onClick={() => setActiveTab("texts")}
            className={
              activeTab === "texts" ? "bg-warning text-dark" : "text-dark"
            }
          >
            {t("library:texts")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="songs"
            active={activeTab === "songs"}
            onClick={() => setActiveTab("songs")}
            className={
              activeTab === "songs" ? "bg-warning text-dark" : "text-dark"
            }
          >
            {t("library:songs")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="collections"
            active={activeTab === "collections"}
            onClick={() => setActiveTab("collections")}
            className={
              activeTab === "collections" ? "bg-warning text-dark" : "text-dark"
            }
          >
            {t("library:collections")}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {activeTab === "texts" && (
        <div>
          <div>
            <Link to="/library/editor">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center btn-dark"
              >
                &#43; {t("library:button_import_content")}
              </Button>
            </Link>
            <Link to="/library/generator">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center btn-dark"
              >
                <Magic className="me-2" /> {t("library:button_create_content_with_ai")}
              </Button>
            </Link>
          </div>
          {importedArticlesAreLoading && (
            <Spinner animation="border" variant="dark" className="mt-4" />
          )}
          {!importedArticlesAreLoading && importedArticles.length === 0 && (
            <Alert variant="secondary" className="mt-4">
              &#128566; {t("library:imported_nothing_yet")}
            </Alert>
          )}
          <Row className="mb-5 mt-4">
            {importedArticles.map((article, index) => (
              <LibraryCard
                article={article}
                index={index}
                key={index}
                is_imported={true}
                is_bookmarked={checkIfArticleIsBookmarked(article._id)}
              />
            ))}
          </Row>
        </div>
      )}
      {activeTab === "songs" && (
        <>
          <div>
            <Link to="/library/songs/editor">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center btn-dark"
              >
                &#43; {t("library:add_song")}
              </Button>
            </Link>
          </div>
          {importedSongsAreLoading && (
            <Spinner animation="border" variant="dark" className="mt-4" />
          )}
          {!importedSongsAreLoading && importedSongs.length === 0 && (
            <Alert variant="secondary" className="mt-4">
              &#128566; {t("library:imported_nothing_yet")}
            </Alert>
          )}
          <Row className="mb-5 mt-4">
            {importedSongs.map((song, index) => {
              return (
                <SongCard
                  song={song}
                  index={index}
                  key={index}
                  is_imported={true}
                  is_bookmarked={checkIfArticleIsBookmarked(song._id)}
                />
              );
            })}
          </Row>
        </>
      )}
      {activeTab === "collections" && (
        <>
          <div>
            <Link to="/library/collection/editor">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center btn-dark"
              >
                &#43; {t("library:create_collection")}
              </Button>
            </Link>
          </div>
          {importedCollectionsAreLoading && (
            <Spinner animation="border" variant="dark" className="mt-4" />
          )}
          {!importedCollectionsAreLoading &&
            importedCollections.length === 0 && (
              <Alert variant="secondary" className="mt-4">
                &#128566; {t("library:imported_nothing_yet")}
              </Alert>
            )}
          <Row className="mb-5 mt-4">
            {importedCollections.map((collection, index) => (
              <CollectionCard
                collection={collection}
                index={index}
                key={index}
              />
            ))}
          </Row>
        </>
      )}
    </>
  );
};

export default LibraryTabImported;
