/* global store */ // Inform ESLint about the global 'store' variable from the plugin

import React, { useContext, useEffect } from "react";
import { Card, Button, Alert, Badge, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import StripePricingPage from "../Components/StripePricingPage";
import AuthContext from "../context/AuthContext";
import AppMetaDataContext from "../context/AppMetaDataContext";
import { Helmet } from "react-helmet-async";
import { useTranslation, Trans } from "react-i18next";

export default function Pricing() {
  const context = useContext(AuthContext);
  const metaData = useContext(AppMetaDataContext);
  const { t } = useTranslation();
  // get the query strings from the URL and put them to an object
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramsObj = {};
  for (const [key, value] of urlParams.entries()) {
    paramsObj[key] = value;
  }

  useEffect(() => {
    const initializeStore = () => {
      if (typeof store !== "undefined") {
        console.log("Store object:", store); // Debug log to see store object

        // Wait for the plugin to be ready
        store.ready(() => {
          console.log("Store is ready");

          // Register products after store is ready
          store.register({
            id: "premium-monthly",
            type: store.PAID_SUBSCRIPTION,
          });

          // Rest of your store setup
          store.when("premium-monthly").approved((product) => {
            product.finish();
            console.log("Purchase approved:", product);
          });

          store.refresh();
        });

        // Add error handler outside ready callback
        store.error((error) => {
          console.error("Store error:", error);
        });
      } else {
        console.error("Store is not available");
      }
    };

    // Wait for both deviceready and platform ready events
    document.addEventListener("deviceready", () => {
      console.log("Device is ready");
      console.log("Store object initial state:", store);
      setTimeout(initializeStore, 300);
    });

    return () => {
      document.removeEventListener("deviceready", initializeStore);
    };
  }, []);

  const handleSubscription = async (plan) => {
    if (typeof store === "undefined") {
      console.error(
        "Store is not initialized. Ensure `cordova-plugin-purchase` is configured."
      );
      return;
    }

    if (plan === "premium") {
      try {
        const product = store.get("premium-monthly");
        if (!product) {
          console.error("Product not found in store.");
          return;
        }

        console.log("Initiating purchase for:", product);
        store.order("premium-monthly");
      } catch (error) {
        console.error("Error initiating purchase:", error);
      }
    } else {
      console.log("Free plan selected. No purchase required.");
    }
  };

  let planBadge;

  switch (context.plan) {
    case "Free plan":
      planBadge = (
        <Badge bg="danger" text="dark">
          {context.plan}
        </Badge>
      );
      break;
    case "Premium plan":
      planBadge = (
        <Badge bg="dark" text="light">
          {context.plan}
        </Badge>
      );
      break;
    default:
      break;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("pricing:title")} - {metaData.appName}
        </title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div
        className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center"
        style={{ maxWidth: "800px" }}
      >
        {paramsObj["extension_translation_quota_exceeded"] && (
          <Alert variant="danger">
            <Alert.Heading>
              {t(
                "pricing:extension_alert_quota_exceeded_for_sentence_translations_title"
              )}
            </Alert.Heading>
            <p>
              {t(
                "pricing:extension_alert_quota_exceeded_for_sentence_translations_description"
              )}
            </p>
          </Alert>
        )}

        <h1 className="display-4">{t("pricing:title")}</h1>
        <p className="lead">{t("pricing:description")}</p>
        <br />

        {context.user_id ? (
          <>
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-10">
                <Card className="mb-3 p-3">
                  <Card.Body>
                    <div className="mb-1">
                      <h5 className="d-flex justify-content-center">
                        {t("pricing:your_current_plan_is")}
                      </h5>
                      <h5>{planBadge}</h5>
                    </div>
                    {/* add a section about free plan limits */}
                    {context.plan === "Free plan" && (
                      <div className="mt-5">
                        <div className="my-3">
                          <span>
                            <strong>
                              {t("pricing:progress_table_word_lookups")}
                            </strong>{" "}
                            {context.word_lookups_count} / 200
                          </span>
                          <ProgressBar
                            now={(context.word_lookups_count / 200) * 100}
                            // label={`${context.word_lookups_count} / 500`}
                          />
                        </div>
                        <div>
                          <span>
                            <strong>
                              {t(
                                "pricing:progress_table_characters_translated"
                              )}
                            </strong>{" "}
                            {context.translations} / 20,000
                            <br />
                            (with the Chrome extension){" "}
                          </span>
                          <ProgressBar
                            now={(context.translations / 20000) * 100}
                            // label={`${context.translations} / 20000`}
                          />
                        </div>
                      </div>
                    )}
                    {context.plan !== "Free plan" && (
                      <div className="pt-3">
                        {t("pricing:need_to_cancel")}{" "}
                        <Link to="/account">
                          {t("pricing:account_settings")}
                        </Link>
                        .
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
            {process.env.NODE_ENV !== "production" && context.user_id && (
              <Alert variant="warning">
                &#128161;{" "}
                <strong>
                  Use 4242 4242 4242 4242 as the credit card number
                </strong>{" "}
                for testing purposes. The name etc. can be random. Just make
                sure the credit card expiration date is in the future.
              </Alert>
            )}
            <StripePricingPage />
          </>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <Badge bg="secondary" text="light" className="mb-3" style={{visibility: "hidden"}}>
                  &nbsp;
                </Badge>
                <Card className="mb-5" style={{ maxWidth: "350px" }}>
                  <Card.Header as="h5">Free plan</Card.Header>
                  <Card.Body>
                    <Card.Title className="mb-4">
                      {t("pricing:free_plan_price")}
                      <br />
                      <span style={{ fontSize: "1rem" }}> &nbsp;</span>
                    </Card.Title>
                    <Card.Text>
                      {t("pricing:free_plan_unlimited_texts_to_study")}
                      <br />
                      <br />
                      <Trans
                        i18nKey="pricing:free_plan_max_200_word_lookups_in_total"
                        components={{ strong: <strong /> }}
                      />
                      <br />
                      <br />
                      {t("pricing:free_plan_flashcards")}
                      <br />
                      <br />
                      {t("pricing:free_plan_unlimited_videos_to_study")}
                      <br />
                      <br />
                      <Trans
                        i18nKey="pricing:free_plan_max_10_imported_youtube_captions_in_total"
                        components={{ strong: <strong /> }}
                      />
                      <br />
                      <br />
                      {t("pricing:free_plan_statistics")}
                      <br />
                      <br />
                      {t("pricing:free_plan_unlimited_saved_words_and_phrases")}
                      <br />
                      <br />
                      <Trans
                        i18nKey="pricing:free_plan_max_10_ai_generated_content_in_total"
                        components={{ strong: <strong /> }}
                      />
                      <br />
                      <br />
                      <Trans
                        i18nKey="pricing:free_plan_chrome_extension_for_translating_words_and_sentences_on_any_website"
                        components={{ strong: <strong /> }}
                      />
                      <br />
                      <br />
                    </Card.Text>
                    <Button variant="success" href="/register">
                      {t("pricing:free_plan_try_for_free")}
                    </Button>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-12 col-md-6">
                <Badge bg="secondary" text="light" className="mb-3">
                  {t("pricing:premium_plan_yearly_discount_badge")}
                </Badge>
                <Card className="mb-5" style={{ maxWidth: "350px" }}>
                  <Card.Header as="h5">Premium plan</Card.Header>
                  <Card.Body>
                    <Card.Title className="mb-4">
                      <span>$5.99 / {t("pricing:premium_plan_month")}</span>
                      <br />
                      <br />
                    </Card.Title>
                    <Card.Text>
                      {t("pricing:premium_plan_unlimited_texts_to_study")}
                      <br />
                      <br />
                      <Trans
                        i18nKey="pricing:premium_plan_unlimited_word_lookups"
                        components={{ strong: <strong /> }}
                      />
                      <br />
                      <br />
                      {t("pricing:premium_plan_flashcards")}
                      <br />
                      <br />
                      {t("pricing:premium_plan_unlimited_videos_to_study")}
                      <br />
                      <br />
                      <Trans
                        i18nKey="pricing:premium_plan_unlimited_imported_youtube_captions"
                        components={{ strong: <strong /> }}
                      />
                      <br />
                      <br />
                      {t("pricing:premium_plan_statistics")}
                      <br />
                      <br />
                      {t(
                        "pricing:premium_plan_unlimited_saved_words_and_phrases"
                      )}
                      <br />
                      <br />
                      <Trans
                        i18nKey="pricing:premium_plan_max_100_ai_generated_content_per_month"
                        components={{ strong: <strong /> }}
                      />
                      <br />
                      <br />
                      <Trans
                        i18nKey="pricing:premium_plan_chrome_extension_for_translating_words_and_sentences_on_any_website"
                        components={{ strong: <strong /> }}
                      />
                      <br />
                      <br />
                    </Card.Text>
                    <Button variant="primary" href="/register">
                      {t("common:sign_up_button")}
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
