import React, { useEffect } from "react";
import Flashcard from "./Flashcard";
import { Button } from "react-bootstrap";
import { ArrowClockwise } from "react-bootstrap-icons";
import axios from "axios";
import { useTranslation } from "react-i18next";

const FlashcardList = ({
  flashcards,
  setFlashcards,
  currentCardIndex,
  setCurrentCardIndex,
  startNewSession,
  isLoading,
  hasLoadedOnce,
  flashcardsType,
}) => {
  const { t } = useTranslation();
  const handleNextCard = () => {
    console.log("Handling the next card...");
    if (currentCardIndex < flashcards.length - 1) {
      console.log("Current card index:", currentCardIndex + 1);
      setCurrentCardIndex(currentCardIndex + 1);
    }
  };

  const handleStrengthChange = (id, strength, manual = false) => {
    const currentCard = flashcards.find((card) => card._id === id);
    let shouldBeRemoved;
    if (strength > currentCard.strength && currentCard.nr_of_tries === 1) {
      shouldBeRemoved = true;
    }
    console.log("Handling the strength change...", id, strength);
    setFlashcards((prevFlashcards) =>
      prevFlashcards.map((card) =>
        card._id === id ? { ...card, strength: strength } : card
      )
    );
    if (strength === 4) {
      removeCardById(id);
    } else {
      if (flashcardsType === "srs") {
        // find the card

        console.log("That's the card:", currentCard);
        console.log("What is manual change?", manual);
        if (currentCard.nr_of_tries === 0 || manual || shouldBeRemoved) {
          removeCardById(id);
        } else {
          handleNextCard();
        }
      }
      if (flashcardsType === "general") {
        handleNextCard();
      }
    }
  };

  const removeCardById = (id) => {
    console.log("Removing card with ID:", id);
    setFlashcards((prevFlashcards) => {
      const newFlashcards = prevFlashcards.filter((card) => card._id !== id);

      // Adjust currentCardIndex if necessary
      if (currentCardIndex >= newFlashcards.length) {
        setCurrentCardIndex(
          newFlashcards.length > 0 ? newFlashcards.length - 1 : 0
        );
      }

      return newFlashcards;
    });
  };

  useEffect(() => {
    console.log("Flashcards currently: ", flashcards);
  }, [flashcards]);

  let isLastCard = currentCardIndex === flashcards.length - 1;
  console.log("Is last card?", isLastCard);
  let noMoreCards = flashcards.length === 0 && !isLoading && hasLoadedOnce;
  console.log("No more cards?", noMoreCards);
  if (noMoreCards === undefined) {
    noMoreCards = true;
    console.log("No more cards is undefined, setting it to true.");
  }

  useEffect(() => {
    if (isLastCard || noMoreCards) {
      console.log(
        "Sending a track event to the server about flashcards finished."
      );
      // Send the Axios request here
      axios
        .post("/api/flashcards/finished", {
          /* Your payload here */
          auto_speech: true,
        })
        .then((response) => {
          console.log(
            "Server response from flashcards finished:",
            response.data
          );
        })
        .catch((error) => {
          console.error(
            "There was an error with flashcards finished server!",
            error
          );
        });
    }
  }, [noMoreCards, isLastCard]);

  return (
    <div className="scene" translate="no">
      {!isLastCard && !noMoreCards && flashcards.length > 0 && (
        <Flashcard
          flashcard={flashcards[currentCardIndex]}
          onStrengthChange={handleStrengthChange}
          flashcardsType={flashcardsType}
        />
      )}
      {(isLastCard || noMoreCards) && (
        <div>
          {/* Success Screen or Message */}
          <h2>{t("flashcards:all_done")}</h2>
          <Button
            variant="primary"
            onClick={startNewSession}
            className="d-flex align-items-center mt-3"
          >
            <ArrowClockwise className="me-2" />
            {t("flashcards:start_new_session")}
          </Button>
          <Button
            variant="warning"
            onClick={() => {
              window.open("https://tally.so/r/wbW5X1", "_blank");
            }}
            className="mt-2"
          >
            {t("flashcards:send_feedback")}
          </Button>
          {/* You can add more content or navigation options here */}
        </div>
      )}
    </div>
  );
};

export default FlashcardList;
