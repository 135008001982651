import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GrammarGuide from "../Components/GrammarGuide";

function GrammarGuidePage() {
  const { language, category, guide } = useParams(); // Extract URL parameters
  const [content, setContent] = useState("");

  useEffect(() => {
    // Dynamically fetch the markdown file based on URL params
    fetch(`/content/${language}/${category}/${guide}.md`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Markdown file not found");
        }
        return res.text();
      })
      .then(setContent)
      .catch((err) => console.error(err));
  }, [language, category, guide]);

  return (
    <div className="container py-4" translate="no">
      <header className="mb-4">
        <h1>
          {language?.charAt(0).toUpperCase() + language?.slice(1)} Grammar Guide
        </h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">{language?.charAt(0).toUpperCase() + language?.slice(1)}</li>
            <li className="breadcrumb-item">{category?.charAt(0).toUpperCase() + category?.slice(1)}</li>
            <li className="breadcrumb-item active" aria-current="page">{guide?.charAt(0).toUpperCase() + guide?.slice(1)}</li>
          </ol>
        </nav>
      </header>
      <main>
        <pre>{content}</pre>{" "}
        <GrammarGuide language={language} type={category} value={guide} />
      </main>
    </div>
  );
}

export default GrammarGuidePage;
