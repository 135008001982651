import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";
import socialImage from "../lingo-champion-social-sharing.png";

function TermsOfService() {
  const metaData = useContext(AppMetaDataContext);

  return (
    <>
      <Helmet>
        <title>Terms of Service - {metaData.appName}</title>
        <meta
          name="description"
          key="description"
          content={metaData.metaDesc}
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "3% 3%" }}>
        <h1 className="text-center" style={{ marginBottom: "7%" }}>
          Terms of Service
        </h1>

        <h2>Account terms</h2>
        <ol>
          <li>
            You have to be a human to use the service. Bots or other automated
            methods are not permitted.
          </li>
          <li>
            Your account must be used only by one person. You cannot share your
            account with other people.
          </li>
          <li>
            One person or legal entity may not mantain more than one account.
          </li>
          <li>You may not use the account for any illegal purposes.</li>
        </ol>

        <h2>Content added by the user</h2>
        <ol>
          <li>
            You are responsible for the content you add to the service. You must
            not add content that is illegal, offensive, or violates any
            third-party rights (including but not limited to copyright laws).
          </li>
          <li>
            You must not add content that is not related to language learning
            (e.g. advertisements, spam, etc.).
          </li>
        </ol>

        <h2>Pricing modifications</h2>
        <ol>
          <li>
            The prices of the service are subject to change with a 7 days
            notice. Such notice may be provided at any time by posting the
            changes to the Lingo Champion website or via email.
          </li>
        </ol>

        <h2>Downgrading</h2>
        <ol>
          <li>
            Downgrading may result in the loss of content, features, or capacity
            of your account. We do not accept any liability for such loss.
          </li>
        </ol>

        <h2>Cancellation</h2>
        <ol>
          <li>
            You are solely responsible for properly canceling your account. You
            can cancel your account at any time by under the "Account" page.
          </li>
          <li>
            Cancelling by phone, email, or any other means will not be accepted.
          </li>
        </ol>

        <h2>Service modifications</h2>
        <ol>
          <li>
            We reserve the right to modify or discontinue the service at any
            time without notice.
          </li>
        </ol>

        <h2>Payment and Refunds</h2>
        <ol>
          <li>
            The service is billed in advance on a monthly basis and is
            non-refundable. There will be no refunds or credits for partial
            months of service, upgrade/downgrade refunds, or refunds for months
            unused with an open account. In order to treat everyone equally, no
            exceptions will be made.
          </li>
        </ol>

        <h2>Copyright and content ownership</h2>
        <ol>
          <li>
            We have the right to use the content you add to the service for any
            purpose and through any means of distribution.
          </li>
          <li>
            You are solely responsible for the content you add to the service
            (including obtaining all necessary rights to the content).
          </li>
          <li>
            We have the right (but not the obligation) to refuse or remove any
            content that is available via the service.
          </li>
        </ol>

        <h2>General conditions</h2>
        <ol>
          <li>
            Your use of the service is at your sole risk. The service is
            provided on an "as is" and "as available" basis.
          </li>
          <li>
            You understand that we use third-party vendors and hosting partners
            to provide the necessary hardware, software, networking, storage,
            and related technology required to run the service.
          </li>
          <li>
            You must not modify, adapt, or hack the service or modify another
            website so as to falsely imply that it is associated with the
            service.
          </li>
          <li>
            You agree not to reproduce, duplicate, copy, sell, resell, or
            exploit any portion of the service, use of the service, or access to
            the service without the express written permission by us.
          </li>
          <li>
            Verbal, physical, written, or other abuse (including threats of
            abuse or retribution) of any service customer, employee, member, or
            officer will result in immediate account termination.
          </li>
          <li>
            You understand that the technical processing and transmission of the
            service, including your content, may be transferred unencrypted and
            involve (a) transmissions over various networks; and (b) changes to
            conform and adapt to technical requirements of connecting networks
            or devices.
          </li>
          <li>
            We reserve the right to temporarily disable your account if your
            usage significantly exceeds the average usage of other service
            customers. Of course, we'll reach out to the account owner before
            taking any action except in rare cases where the level of use may
            negatively impact the performance of the service for other
            customers.
          </li>
          <li>
            We may modify these terms from time to time without any notice.
          </li>
        </ol>

        <br />
        <br />
        <br />
        <br />
      </Container>
    </>
  );
}

export default TermsOfService;
