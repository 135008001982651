import { useRef, useContext, useCallback } from "react";
import AnalyticsContext from "../context/AnalyticsContext";

export const useYouTubeAudioTracking = () => {
  const analyticsContext = useContext(AnalyticsContext);
  const currentTimeRef = useRef(0);
  const lastTimeUpdate = useRef(0);
  const timeTrackingInterval = useRef(null);
  const isPlaying = useRef(false);
  const isSeeking = useRef(false);

  const handleStateChange = useCallback(
    (event) => {
      const player = event.target;

      if (event.data === 1) {
        // Playing
        isPlaying.current = true;
        // Reset the last time update to current time when starting playback
        lastTimeUpdate.current = player.getCurrentTime();

        if (timeTrackingInterval.current) {
          clearInterval(timeTrackingInterval.current);
        }

        timeTrackingInterval.current = setInterval(() => {
          if (!isPlaying.current) return;

          const currentTime = player.getCurrentTime();
          const timeDiff = currentTime - lastTimeUpdate.current;

          // Only count time if we're not seeking and the difference is reasonable
          // (less than 2 seconds to account for small network delays)
          if (timeDiff > 0 && timeDiff < 2 && !isSeeking.current) {
            analyticsContext.updateAudioStatistics(timeDiff);
          }
          lastTimeUpdate.current = currentTime;
        }, 1000);
      } else if (event.data === 2 || event.data === 0) {
        // Paused or Ended
        isPlaying.current = false;
        if (timeTrackingInterval.current) {
          clearInterval(timeTrackingInterval.current);
        }

        // Only track final segment if we're not seeking
        if (!isSeeking.current) {
          const finalTime = player.getCurrentTime();
          const finalDiff = finalTime - lastTimeUpdate.current;
          if (finalDiff > 0 && finalDiff < 2) {
            analyticsContext.updateAudioStatistics(finalDiff);
          }
        }
        lastTimeUpdate.current = player.getCurrentTime();
      } else if (event.data === 3) {
        // Buffering
        // Don't track time during buffering, just update the last time
        lastTimeUpdate.current = player.getCurrentTime();
      }
    },
    [analyticsContext]
  );

  const handlePlaybackRateChange = useCallback(
    (event) => {
      // Just update the last time without tracking the difference
      lastTimeUpdate.current = event.target.getCurrentTime();
    },
    [analyticsContext]
  );

  const handleSeeking = useCallback((event) => {
    isSeeking.current = true;
    // Update the last time to the new position
    lastTimeUpdate.current = event.target.getCurrentTime();
  }, []);

  const handleSeeked = useCallback((event) => {
    isSeeking.current = false;
    // Update the last time to the new position
    lastTimeUpdate.current = event.target.getCurrentTime();
  }, []);

  const cleanup = useCallback(() => {
    isPlaying.current = false;
    if (timeTrackingInterval.current) {
      clearInterval(timeTrackingInterval.current);
    }
  }, []);

  return {
    handleStateChange,
    handlePlaybackRateChange,
    handleSeeking,
    handleSeeked,
    cleanup,
  };
};
