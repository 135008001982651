import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Modal,
  Button,
  ListGroup,
  Form,
  Placeholder,
  Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";

const CollectionsModal = ({ show, setShow, articleId }) => {
  const { t } = useTranslation();
  const context = React.useContext(AuthContext);
  const [collections, setCollections] = useState([]);
  const [newCollectionName, setNewCollectionName] = useState("");
  const [selectedCollections, setSelectedCollections] = useState(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCollections, setIsLoadingCollections] = useState(false);

  useEffect(() => {
    const fetchCollections = async () => {
      setIsLoadingCollections(true);
      try {
        const response = await axios.get(`/api/user/collections`, {
          params: {
            language: context.getSelectedLanguagePair().language_learning.code,
          },
        });
        setCollections(response.data.collections || []);

        // Initialize selectedCollections with collections that already contain the article
        const preSelectedCollections = new Set(
          response.data.collections
            .filter((collection) =>
              collection.articles.some(
                (article) => article.article_id === articleId
              )
            )
            .map((collection) => collection._id)
        );
        setSelectedCollections(preSelectedCollections);
      } catch (error) {
        console.error("Error fetching collections:", error);
      } finally {
        setIsLoadingCollections(false);
      }
    };

    if (show) {
      fetchCollections();
    }
  }, [show, context, articleId]);

  const handleCheckboxChange = (collectionId) => {
    setSelectedCollections((prevState) => {
      const newSet = new Set(prevState);
      if (newSet.has(collectionId)) {
        newSet.delete(collectionId);
      } else {
        newSet.add(collectionId);
      }
      return newSet;
    });
  };

  const handleCollectionSelect = async () => {
    setIsLoading(true);
    try {
      // Add article to selected collections
      await axios.post(`/api/user/collections/add-article`, {
        articleId: articleId,
        collectionIds: Array.from(selectedCollections),
      });
      setShow(false);
      setSelectedCollections(new Set());
    } catch (error) {
      console.error("Error adding article to collections:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateNewCollection = async (e) => {
    e.preventDefault();
    if (!newCollectionName.trim()) return;

    try {
      const response = await axios.post("/api/user/collection", {
        title: newCollectionName,
        type: "other",
        privacy: "private",
        language: context.getSelectedLanguagePair().language_learning.code,
      });

      setCollections([...collections, response.data.collection]);
      setNewCollectionName("");
    } catch (error) {
      console.error("Error creating new collection:", error);
    }
  };

  const handleClose = () => {
    setShow(false);
    setSelectedCollections(new Set());
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("library:select_collections")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoadingCollections ? (
          <div className="text-center p-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <ListGroup>
              {collections.map((collection) => (
                <ListGroup.Item
                  key={collection._id}
                  onClick={() => handleCheckboxChange(collection._id)} // Toggle checkbox when clicking the item
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "start",
                  }} // Align items to the top
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ marginRight: "10px", marginTop: "0.4em" }} // Space between this div and the text
                  >
                    <Form.Check
                      type="checkbox"
                      id={`checkbox-${collection._id}`}
                      checked={selectedCollections.has(collection._id)}
                      onChange={() => handleCheckboxChange(collection._id)}
                      onClick={(e) => e.stopPropagation()} // Prevent ListGroup.Item's onClick from firing
                      style={{ marginRight: "10px" }} // Space between the checkbox and the image
                    />
                    {collection.cover_image ? (
                      <img
                        src={collection.cover_image}
                        alt=""
                        style={{
                          width: "30px",
                          height: "30px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Placeholder
                        as="span"
                        style={{
                          width: "30px",
                          height: "30px",
                          backgroundColor: "#e0e0e0",
                        }}
                      />
                    )}
                  </div>
                  <div className="d-flex flex-column">
                    <div>{collection.title}</div>
                    <small className="text-muted">
                      {collection.articles.length}{" "}
                      {t("library:collection_selection_modal_items")}
                    </small>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Form onSubmit={handleCreateNewCollection} className="mt-3">
              <Form.Group controlId="formNewCollection">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("library:collection_name_placeholder")}
                    value={newCollectionName}
                    onChange={(e) => setNewCollectionName(e.target.value)}
                  />
                  <Button variant="primary" type="submit" className="ms-2">
                    {t("library:create")}
                  </Button>
                </div>
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={handleCollectionSelect}
          disabled={isLoading}
        >
          {isLoading ? t("common:saving") : t("library:save_to_collections")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CollectionsModal;
