import React, { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import WordEdit from "../Components/WordEdit";
import "./TranslationPanel.css";
import AIBrainImg from "../ai-brain-3.png";
import SpeechButton from "../Components/SpeechButton";
import StrengthBar from "../Components/StrengthBar";
import Badge from "react-bootstrap/Badge";
import GrammarGuide from "../Components/GrammarGuide";
import Modal from "react-bootstrap/Modal";
import { SignpostSplit } from "react-bootstrap-icons";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TranslationPanel = ({
  selectedWord,
  setSelectedWord,
  addUnknownWordToKnownWords,
  updateKnownWordInKnownWords,
  isFullScreen,
  setIsFullScreen,
  closeTranslationsPanel,
  fullTextTokens,
  clearTranslationPanelSelectedWord,
  speakOnRender = true,
}) => {
  // TODO: remove this
  const { known_words, markWordAsKnown, updateWordTranslation } =
    useContext(AuthContext);
  const context = useContext(AuthContext);
  const { t } = useTranslation();
  const [possibleTranslations, setPossibleTranslations] = useState([]);
  // Define the initial state for currentWordDetails
  const initialWordDetails = {
    explanation: null,
    tense: null,
    tenseTranslation: null,
    partOfSpeech: null,
    partOfSpeechTranslation: null,
  };

  // Use the initial state in the useState hook
  const [currentWordDetails, setCurrentWordDetails] =
    useState(initialWordDetails);
  // Initialize localSelectedWord with a default object
  const [localSelectedWord, setLocalSelectedWord] = useState(
    selectedWord || {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isAILoading, setIsAILoading] = useState(false);
  const currentWordRef = useRef(localSelectedWord);
  const [width, setWidth] = useState(document.documentElement.clientWidth);
  const [showGrammarModal, setShowGrammarModal] = useState(false);
  const [grammarModal, setGrammarModal] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(document.documentElement.clientWidth);
      if (width < 768) {
        setIsFullScreen(true);
      } else {
        setIsFullScreen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures this effect runs only on mount and unmount

  useEffect(() => {
    console.log("selectedWord changed: ", selectedWord);
    if (
      selectedWord &&
      selectedWord.word &&
      selectedWord.word !== localSelectedWord.word
    ) {
      console.log("Translation panel received the word: ", selectedWord);
      console.log("Setting it as localSelectedWord");

      // Clear currentWordDetails using the initial state
      setCurrentWordDetails(initialWordDetails);

      // Set the new localSelectedWord
      setLocalSelectedWord({ ...selectedWord });

      // Reset possible translations
      setPossibleTranslations([]);
    } else if (!selectedWord) {
      console.log("selectedWord is null, clearing the translation panel");
      setLocalSelectedWord({});
    }
  }, [selectedWord, localSelectedWord.word]);

  useEffect(() => {
    console.log("localSelectedWord changed: ", localSelectedWord);

    if (
      localSelectedWord?.word &&
      localSelectedWord.word !== "" &&
      localSelectedWord.word !== undefined
    ) {
      console.log("Showing the translation panel: ", localSelectedWord);
      showTranslationPanel();
    }

    // find the active language pair codes
    const selectedLanguagePair = context.language_pairs.find(
      (pair) => pair.is_selected
    );
    console.log("Active language pair: ", selectedLanguagePair);

    if (!selectedWord || !selectedWord?.word || !localSelectedWord.word) {
      // show the instructions when no word is selected
      document.getElementById("translationsPanelInstructions").style.display =
        "block";
      return;
    }
    // hide the instructions when looking up a word
    document.getElementById("translationsPanelInstructions").style.display =
      "none";

    // Check if word_lookups_count is 500 or more
    if (context.plan_word_lookups_count_limit_reached) {
      console.log("Word lookups count is 500 or more, skipping API requests.");
      return;
    }

    // send word to OpenAI for explanation
    setIsAILoading(true);
    axios
      .post("/api/explain-word", {
        word: selectedWord.word,
        context: selectedWord.sentence,
        language_learning: selectedLanguagePair.language_learning.name,
        language_base: selectedLanguagePair.language_base.name,
      })
      .then((response) => {
        console.log("RESPONSE FROM EXPLAIN ONE WORD: ", response.data);
        console.log("--- ORIGINAL WORD: ", response.data.original);
        console.log("--- LOCAL SELECTED WORD: ", localSelectedWord.word);

        // Compare against the current value in the ref
        // NOTE: dunno why localSelectedWord.word doesn't work here properly when clicking on multiple words fast

        if (response.data.original === currentWordRef.current.word) {
          const formattedExplanation = escapeQuotesAndNewlines(
            response.data.translation
          );
          setCurrentWordDetails((prevDetails) => ({
            ...prevDetails,
            explanation: formattedExplanation,
          }));
          console.log("-- INSERTED EXPLANATION");
          setIsAILoading(false);
        } else {
          console.log("-- SKIPPED EXPLANATION: Word has changed");
        }
      })
      .catch((err) => {
        setIsAILoading(false);
        setCurrentWordDetails((prevDetails) => ({
          ...prevDetails,
          explanation: "No AI explanation available for this term.",
        }));
        console.log("Error from explaining word backend: ", err);
      });

    axios
      .post("/api/translate-word-with-ai", {
        word: selectedWord.word,
        context: selectedWord.sentence,
        language_learning: selectedLanguagePair.language_learning.name,
        language_base: selectedLanguagePair.language_base.name,
      })
      .then((response) => {
        console.log(
          "RESPONSE FROM TRANSLATE ONE WORD WITH AI: ",
          response.data
        );
        console.log("--- ORIGINAL WORD: ", response.data.original);
        console.log("--- LOCAL SELECTED WORD: ", localSelectedWord.word);

        context.updateWordLookupsCount(response.data.word_lookups_count);

        // Compare against the current value in the ref
        if (response.data.original === currentWordRef.current.word) {
          response.data.translation.term_possible_translations =
            response.data.translation.term_possible_translations.map(
              (translation) => decodeUnicodeEscapeSequences(translation)
            );

          setPossibleTranslations((prevTranslation) => {
            const newValues =
              response.data.translation.term_possible_translations.filter(
                (value) => !prevTranslation.includes(value)
              );
            return [...prevTranslation, ...newValues];
          });

          if (response.data.translation.term_tense) {
            setCurrentWordDetails((prevDetails) => ({
              ...prevDetails,
              tense: response.data.translation.term_tense,
              tenseTranslation:
                response.data.translation.term_tense_translation,
            }));
            console.log("Current tense: ", currentWordDetails.tense);
          }

          if (response.data.translation.term_part_of_speech === "Noun") {
            setCurrentWordDetails((prevDetails) => ({
              ...prevDetails,
              partOfSpeech: response.data.translation.term_part_of_speech,
              partOfSpeechTranslation:
                response.data.translation.term_part_of_speech_translation,
            }));
            console.log(
              "Current part of speech: ",
              currentWordDetails.partOfSpeechTranslation
            );
          }

          console.log("-- INSERTED AI TRANSLATIONS");
        } else {
          console.log("-- SKIPPED AI TRANSLATIONS: Word has changed");
        }
      })
      .catch((err) => {
        setCurrentWordDetails((prevDetails) => ({
          ...prevDetails,
          explanation: "No AI translations available for this term.",
        }));
        console.log("Error from AI translation word backend: ", err);
      });

    if (
      !localSelectedWord.translation &&
      !localSelectedWord.is_not_a_word &&
      localSelectedWord.word
    ) {
      console.log("DECIDED TO DO TRANSLATIONS: ", localSelectedWord);
      setIsLoading(true);
      const request = {
        word: selectedWord.word,
        language_learning: selectedLanguagePair.language_learning.code,
        language_base: selectedLanguagePair.language_base.code,
      };
      axios
        .post("/api/translate-word", request)
        .then((response) => {
          console.log("RESPONSE FROM TRANSLATE ONE WORD: ", response.data);
          setIsLoading(false);
          if (response.data.original === currentWordRef.current.word) {
            setPossibleTranslations((prevTranslation) => {
              const newValues = response.data.translation.filter(
                (value) => !prevTranslation.includes(value)
              );
              return [...prevTranslation, ...newValues];
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [localSelectedWord]);

  // useEffect for TTS
  useEffect(() => {
    if (!localSelectedWord.word || localSelectedWord.word === "") {
      // display instructions
      document.getElementById("translationsPanelInstructions").style.display =
        "block";
      return;
    }
    // Update the ref every time localSelectedWord changes
    currentWordRef.current = localSelectedWord;
  }, [localSelectedWord]);

  // REVIEW: this is probably a duplication of clearTranslationPanelSelectedWord
  function clearTranslationPanelSelectedWordContent() {
    console.log(
      "Clearing the translation panel content and displaying instruction again."
    );
    setLocalSelectedWord({});
    // show instructions
    // this is already done in the useffect:
    // document.getElementById("translationsPanelInstructions").style.display = "block";
  }

  function handleWordAddClick(e, translation) {
    e.stopPropagation();
    if (localSelectedWord.known) {
      updateKnownWordInKnownWords(localSelectedWord, translation, 0);
    } else {
      addUnknownWordToKnownWords(localSelectedWord.word, translation, 0);
    }
    clearTranslationPanelSelectedWordContent(); // Ensure the panel is cleared
    setSelectedWord({});
    if (isFullScreen) closeTranslationsPanel();
  }

  function showTranslationPanel() {
    // display translations panel if it's not visible (closed or on mobile where it's hidden by default)
    const translationsElement = document.getElementById("translations");
    translationsElement.style.display = "block";

    if (isFullScreen) {
      const closeButton = document.getElementById(
        "closeTranslationsPanelButton"
      );
      if (closeButton) {
        closeButton.style.display = "block";
      }
      // this is for mobile only to make sure there isn't a window scrollbar that would start showing content when scrolling on mobile
      const textContent = document.getElementById("textContent");
      if (textContent) {
        textContent.style.display = "none";
      }
    }

    if (document.documentElement.clientWidth < 768) {
      console.log("Set translations panel to full screen.");
      setIsFullScreen(true);
    }
  }

  function handleTenseClick() {
    console.log("Current tense: ", currentWordDetails.tense);

    // Find the active language pair codes
    const selectedLanguagePair = context.language_pairs.find(
      (pair) => pair.is_selected
    );

    if (!selectedLanguagePair) {
      console.error("No selected language pair found");
      return;
    }

    // Format the tense to lowercase and replace spaces with hyphens
    const formattedTense = currentWordDetails.tense
      .toLowerCase()
      .replace(/\s+/g, "-");

    // Set the modal state variables
    setGrammarModal({
      value: formattedTense,
      type: "tenses",
    });
    setSelectedLanguage(selectedLanguagePair.language_learning.name);
    setShowGrammarModal(true);
  }

  function handlePartOfSpeechClick() {
    console.log("Current part of speech: ", currentWordDetails.partOfSpeech);

    // Find the active language pair codes
    const selectedLanguagePair = context.language_pairs.find(
      (pair) => pair.is_selected
    );

    if (!selectedLanguagePair) {
      console.error("No selected language pair found");
      return;
    }

    // Format the tense to lowercase and replace spaces with hyphens
    const formattedPartOfSpeech = currentWordDetails.partOfSpeech
      .toLowerCase()
      .replace(/\s+/g, "-");

    // Set the modal state variables
    setGrammarModal({
      value: formattedPartOfSpeech,
      type: "pos",
    });
    setSelectedLanguage(selectedLanguagePair.language_learning.name);
    setShowGrammarModal(true);
  }

  return (
    <>
      <div id="translationPanel" translate="no">
        {/* <Button
          id="closeTranslationsPanelButton"
          variant="light"
          onClick={closeTranslationsPanel}
          className={isFullScreen ? "closeTranslationsPanelButtonBigger" : ""}
        >
          &#x2715;
        </Button> */}
        <TranslationPanelInstructions />

        {"word" in localSelectedWord && (
          <div id="translationPanelSelectedWordContent">
            <h5>
              {
                <SpeechButton
                  word={localSelectedWord.word}
                  speakOnRender={speakOnRender}
                />
              }
              {localSelectedWord.word}
            </h5>
            {/* <Badge bg="secondary" className="me-2">
              Feminine
            </Badge>
            <Badge bg="secondary" className="me-2">
              Noun
            </Badge> */}

            {(currentWordDetails.tenseTranslation ||
              currentWordDetails.tense) && (
              <Badge
                bg="success"
                className="me-2 btn grammar-guide-button"
                onClick={() => handleTenseClick()}
              >
                <SignpostSplit className="me-1" />
                {currentWordDetails.tenseTranslation ||
                  currentWordDetails.tense}
              </Badge>
            )}
            {currentWordDetails.partOfSpeech && (
              <Badge
                bg="success"
                className="me-2 btn grammar-guide-button"
                onClick={() => handlePartOfSpeechClick()}
              >
                <SignpostSplit className="me-1" />
                {currentWordDetails.partOfSpeechTranslation ||
                  currentWordDetails.partOfSpeech}
              </Badge>
            )}
            {"strength" in localSelectedWord &&
              localSelectedWord.translation && (
                <>
                  <p>
                    {t("readers:translation_panel_word_strength")}{" "}
                    {localSelectedWord.strength}
                  </p>
                  {/* BUG: when saving the strength of an existing word, the word is not selected anymore in the translation panel */}
                  {/* <StrengthBar word={localSelectedWord} setWord={setLocalSelectedWord} updateKnownWordInKnownWords={updateKnownWordInKnownWords} /> */}
                </>
              )}

            {context.plan_word_lookups_count_limit_reached ? (
              <Alert variant="warning" className="mb-4 mt-3">
                {t("readers:translation_panel_word_lookup_limit_reached")}
                <Link to="/pricing">
                  {t("readers:translation_panel_upgrade_to_higher_plan")}
                </Link>
              </Alert>
            ) : (
              <div id="AIExplanation" className="mb-4 mt-3">
                <ExplanationBox
                  currentExplanation={currentWordDetails.explanation}
                  isAILoading={isAILoading}
                />
              </div>
            )}

            {!localSelectedWord.known &&
              (!context.known_words || context.known_words?.length < 500) && (
                <>
                  <p>{t("readers:translation_panel_word_not_seen_yet")}</p>
                </>
              )}

            {localSelectedWord.known &&
              localSelectedWord.translation === undefined &&
              (!context.known_words || context.known_words?.length < 500) && (
                <>
                  <p>
                    {t(
                      "readers:translation_panel_word_skipped_when_turning_pages"
                    )}
                  </p>
                </>
              )}

            <h6 className="my-2">
              {t("readers:translation_panel_saved_translation")}
            </h6>
            <WordEdit
              localSelectedWord={localSelectedWord}
              handleWordAddClick={handleWordAddClick}
              clearTranslationPanelSelectedWordContent={
                clearTranslationPanelSelectedWordContent
              }
            />

            <div className="your-container-class">
              {!context.plan_word_lookups_count_limit_reached && (
                <h6 className="my-2">
                  {t("readers:translation_panel_possible_translations")}
                </h6>
              )}
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <ListGroup as="ul">
                  {possibleTranslations.length > 0 &&
                    possibleTranslations.map((translation, index) => (
                      <ListGroup.Item
                        as="a"
                        className="d-flex justify-content-between align-items-start translationPanelListItem"
                        key={index}
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">{translation}</div>
                        </div>
                        <Button
                          onTouchStart={(e) => {
                            handleWordAddClick(e, translation);
                          }}
                          onClick={(e) => {
                            handleWordAddClick(e, translation);
                          }}
                          size="sm"
                        >
                          {t("readers:translation_panel_add")}
                        </Button>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              )}
            </div>
          </div>
        )}
        <Modal
          show={showGrammarModal}
          onHide={() => setShowGrammarModal(false)}
          fullscreen
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t("readers:translation_panel_grammar_guide_modal_title")}
              <sup>
                <Badge bg="secondary">{t("common:beta")}</Badge>
              </sup>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GrammarGuide
              language={selectedLanguage}
              value={grammarModal?.value}
              type={grammarModal?.type}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

function TranslationPanelInstructions() {
  const { t } = useTranslation();

  return (
    <div id="translationsPanelInstructions">
      <p>
        &#128161;{" "}
        {t(
          "readers:translation_panel_instructions_click_on_any_word_to_see_translations"
        )}
      </p>
      <h6>
        {t(
          "readers:translation_panel_instructions_what_do_the_word_colors_mean"
        )}
      </h6>
      <div className="my-3">
        <div className="mb-2">
          <OverlayTrigger
            placement="left"
            delay={{ show: 50, hide: 100 }}
            overlay={
              <Tooltip style={{ zIndex: 20000 }}>
                {t("readers:translation_panel_instructions_new_word_tooltip")}
              </Tooltip>
            }
            popperConfig={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 10], // Change the numbers to control x, y offset
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    padding: 10, // Change this value to control padding
                  },
                },
                {
                  name: "flip",
                  options: {
                    padding: 10, // Change this value to control padding
                  },
                },
              ],
            }}
          >
            <span className="unknown">
              {t("readers:translation_panel_instructions_new_word")}
            </span>
          </OverlayTrigger>
        </div>
        <div className="mb-2">
          <OverlayTrigger
            placement="left"
            // set show property to false when visualviewport width is less than 480, otherwise don't use show property
            show={
              document.documentElement.clientWidth < 1024 ? false : undefined
            }
            delay={{ show: 50, hide: 100 }}
            overlay={
              <Tooltip style={{ zIndex: 20000 }}>
                {t(
                  "readers:translation_panel_instructions_learning_word_tooltip"
                )}
              </Tooltip>
            }
            popperConfig={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 10], // Change the numbers to control x, y offset
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    padding: 10, // Change this value to control padding
                  },
                },
                {
                  name: "flip",
                  options: {
                    padding: 10, // Change this value to control padding
                  },
                },
              ],
            }}
          >
            <span className="known">
              {t("readers:translation_panel_instructions_learning_word")}
            </span>
          </OverlayTrigger>
        </div>
        <div>
          <OverlayTrigger
            placement="left"
            // set show property to false when visualviewport width is less than 480, otherwise don't use show property
            show={
              document.documentElement.clientWidth < 1024 ? false : undefined
            }
            delay={{ show: 50, hide: 100 }}
            overlay={
              <Tooltip style={{ zIndex: 20000 }}>
                {t(
                  "readers:translation_panel_instructions_skipped_word_tooltip"
                )}
              </Tooltip>
            }
            popperConfig={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 10], // Change the numbers to control x, y offset
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    padding: 10, // Change this value to control padding
                  },
                },
                {
                  name: "flip",
                  options: {
                    padding: 10, // Change this value to control padding
                  },
                },
              ],
            }}
          >
            <span className="skipped">
              {t("readers:translation_panel_instructions_skipped_word")}
            </span>
          </OverlayTrigger>
        </div>
      </div>
      <p>
        &#128161;
        <span className="unknown">
          {t("readers:translation_panel_instructions_new_words")}
        </span>
        {t(
          "readers:translation_panel_instructions_new_words_will_be_marked_as_skipped"
        )}
      </p>
      <p>
        &#128161;{" "}
        {t("readers:translation_panel_instructions_mark_multiple_words")}
        <span className="highlighted">
          {t("readers:translation_panel_instructions_multiple_words")}
        </span>
        {t(
          "readers:translation_panel_instructions_multiplewords_to_look_up_and_save_phrases"
        )}
      </p>
    </div>
  );
}

function findSentenceContainingToken(tokens, tokenIndex) {
  // Validate the tokenIndex
  if (tokenIndex < 0 || tokenIndex >= tokens.length) {
    throw new Error("Invalid token index");
  }

  // Find the start of the sentence
  let start = 0; // Assume start of array if no punctuation found
  for (let i = tokenIndex - 1; i >= 0; i--) {
    if ([".", "!", "?"].includes(tokens[i])) {
      start = i + 1;
      break;
    }
  }

  // Find the end of the sentence
  let end = tokens.length; // Assume end of array if no punctuation found
  for (let i = tokenIndex + 1; i < tokens.length; i++) {
    if ([".", "!", "?"].includes(tokens[i])) {
      end = i + 1;
      break;
    }
  }

  // Reconstruct and return the sentence
  return tokens.slice(start, end).join("");
}

function ExplanationBox({ currentExplanation, isAILoading }) {
  const [expanded, setExpanded] = useState(true);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textContainerRef = useRef(null);
  const context = useContext(AuthContext);
  const { t } = useTranslation();
  useEffect(() => {
    if (isAILoading) {
      // Reset isOverflowing when loading starts
      setIsOverflowing(false);
      setExpanded(context.settings?.expand_ai_explanation_box);
    } else {
      setExpanded(true);
      const checkOverflow = () => {
        const current = textContainerRef.current;
        if (current) {
          setIsOverflowing(current.scrollHeight > current.clientHeight);
        }
      };

      checkOverflow();
      window.addEventListener("resize", checkOverflow);

      return () => window.removeEventListener("resize", checkOverflow);
    }
  }, [isAILoading, currentExplanation]);

  useEffect(() => {
    setExpanded(context.settings?.expand_ai_explanation_box);
  }, [isAILoading]);

  const handleExplanationClick = () => {
    if (!isAILoading) {
      setExpanded(!expanded);
    }
  };

  const explanationBoxStyle = {
    minHeight: "4.5em",
    maxHeight: expanded ? "none" : "4.5em",
    overflow: "hidden",
    position: "relative",
  };

  const loadingStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "4.5em",
  };

  const cardClassName = `explanation-card${
    isOverflowing && !expanded ? " show-ellipsis" : ""
  }`;

  return (
    <Card
      className={cardClassName}
      onClick={handleExplanationClick}
      style={{ cursor: isAILoading ? "default" : "pointer" }}
    >
      <Card.Body>
        {isAILoading ? (
          <div style={loadingStyle}>
            <Spinner animation="grow" size="sm" className="me-2" />
            {t(
              "readers:translation_panel_instructions_trying_to_explain_with_ai"
            )}
          </div>
        ) : (
          <div style={explanationBoxStyle} ref={textContainerRef}>
            <Card.Text style={{ height: "100%", marginBottom: 0 }}>
              <Image src={AIBrainImg} width={13} className="mb-1" />{" "}
              {currentExplanation}
            </Card.Text>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

// Function to decode Unicode escape sequences
function decodeUnicodeEscapeSequences(input) {
  return input.replace(/\\u[\dA-Fa-f]{4}/g, (match) =>
    String.fromCharCode(parseInt(match.substr(2), 16))
  );
}

function escapeQuotesAndNewlines(input) {
  return input
    .replace(/\\"/g, '"') // Replace escaped quotes
    .replace(/\\n/g, "\n"); // Replace escaped newlines
}

export default TranslationPanel;
