import React, { Component } from "react";
import axios from "axios";
import AuthContext from "./AuthContext";

const AnalyticsContext = React.createContext();

export class AnalyticsProvider extends Component {
  static contextType = AuthContext;

  // Define UPDATE_INTERVAL as a class property
  UPDATE_INTERVAL = 30; // seconds

  state = {
    audio_played_this_session: {
      seconds_played: 0,
    },
  };

  updateAudioStatistics = (newSeconds) => {
    const { audio_played_this_session } = this.state;
    const totalSeconds = audio_played_this_session.seconds_played + newSeconds;

    // Only update if we have full 30 seconds to report
    const secondsToReport =
      Math.floor(totalSeconds / this.UPDATE_INTERVAL) * this.UPDATE_INTERVAL;

    if (secondsToReport >= this.UPDATE_INTERVAL) {
      // Get required data from AuthContext
      const selectedLanguagePair = this.context.getSelectedLanguagePair();
      const timezone = this.context.timezone;

      if (!selectedLanguagePair || !timezone) {
        console.error("Missing required data from AuthContext");
        return;
      }

      // Send tracking request to server
      axios
        .post("/api/user/track-audio-time", {
          seconds: secondsToReport,
          language_pair_id: selectedLanguagePair._id,
          timezone: timezone,
        })
        .then((response) => {
          console.log("Audio time tracked successfully:", response.data);
        })
        .catch((error) => {
          console.error("Error tracking audio time:", error);
        });

      // Update state with remaining seconds (those that didn't make a full 30)
      this.setState({
        audio_played_this_session: {
          ...audio_played_this_session,
          seconds_played: totalSeconds - secondsToReport,
        },
      });
    } else {
      // Just update the accumulated seconds
      this.setState({
        audio_played_this_session: {
          ...audio_played_this_session,
          seconds_played: totalSeconds,
        },
      });
    }
  };

  render() {
    const { audio_played_this_session } = this.state;
    const { updateAudioStatistics } = this;

    return (
      <AnalyticsContext.Provider
        value={{
          audio_played_this_session,
          updateAudioStatistics,
        }}
      >
        {this.props.children}
      </AnalyticsContext.Provider>
    );
  }
}

export default AnalyticsContext;
