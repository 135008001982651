import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal, Button, Form, Spinner, Alert } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";

const WordEditModal = ({ show, setShow, wordToEdit, knownWordsArray }) => {
  const context = useContext(AuthContext);
  const { t } = useTranslation();
  const [wordWord, setWordWord] = useState(wordToEdit?.word || "");
  const [wordTranslation, setWordTranslation] = useState(
    wordToEdit?.translation || ""
  );
  const [wordStrength, setWordStrength] = useState(
    wordToEdit?.strength?.toString() || "0"
  );
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleWordSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage("");
    setLoading(true);
    console.log("Saving word...", wordWord, wordTranslation, wordStrength);

    // Convert wordStrength to a number before sending the request
    const request = {
      wordId: wordToEdit._id,
      word: wordWord,
      translation: wordTranslation,
      strength: parseInt(wordStrength, 10),
      languagePairId: context.getSelectedLanguagePair()._id
    };

    axios
      .put("/api/user/update-word", request)
      .then((response) => {
        console.log("RESPONSE FROM UPDATE WORDS backend: ", response.data);
        // find the word in the words array and update the knownWordsArray with the new word from response.data.updatedWord
        context.updateKnownWordInContext(response.data.updatedWord);
        setShow(false);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ERROR WHEN UPDATING WORDS in backend:", err);
        setLoading(false);
        setErrorMessage(err.message);
      });
  };

  // Set the word to edit when the modal is opened
  useEffect(() => {
    setWordWord(wordToEdit?.word || "");
    setWordTranslation(wordToEdit?.translation || "");
    setWordStrength(wordToEdit?.strength?.toString() || "0");
  }, [wordToEdit]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose} translate="no">
      <Modal.Header closeButton>
        <Modal.Title>{t("readers:edit_word")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mt-3">
          <Form.Group>
            <div>
              <Form.Label>{t("readers:word")}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter word"
                maxLength={30}
                value={wordWord}
                onChange={(e) => setWordWord(e.target.value)}
              />
              <Form.Label className="mt-2">
                {t("readers:translation")}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter translation"
                maxLength={50}
                value={wordTranslation}
                onChange={(e) => setWordTranslation(e.target.value)}
              />
              <Form.Label className="mt-2">{t("readers:strength")}</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={wordStrength}
                onChange={(e) => setWordStrength(e.target.value)}
              >
                <option value="0">0 ({t("readers:0_new")})</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5 ({t("readers:5_learned")})</option>
              </Form.Select>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={handleWordSave}
          disabled={wordWord.trim().length === 0 || loading}
        >
          {loading && (
            <Spinner animation="grow" size="sm" className="me-1 mb-0" />
          )}
          {t("readers:save")}
        </Button>
      </Modal.Footer>
      {errorMessage && (
        <Modal.Footer>
          <Alert variant="danger">{errorMessage}</Alert>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default WordEditModal;
