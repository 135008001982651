export const recentReleaseNotes = [
  {
    title: "Import any file for studying",
    date: "14 Feb 2025",
    updates: [
      "You can now import almost any file for studying. This includes PDFs, DOCX, ODT, and more.",
      "The files are automatically converted to an editable text format.",
      "Long texts are automatically split to improve reading experience.",
      "The editor also got some design improvements.",
    ],
  },
  {
    title: "PDF import improvements",
    date: "14 Feb 2025",
    updates: [
      "The PDF import now uses a new parser that can handle more complex PDFs.",
    ],
  },
  {
    title: "New audio player setting",
    date: "13 Feb 2025",
    updates: [
      "The audio player now has a setting for pausing the audio after each sentence.",
      "Fixed a bug where the audio player briefly jumped back to the previous sentence when clicking on a sentence.",
    ],
  },
  {
    title: "German news sources updated",
    date: "13 Feb 2025",
    updates: [
      "Added more news sources to the German language - filtering out the less trustworthy ones.",
    ],
  },
  {
    title: "New English course content",
    date: "13 Feb 2025",
    updates: [
      "Added new stories to the English course.",
      "There's also a new course on American History.",
    ],
  },
  {
    title: "Bug fix: Collection re-ordering",
    date: "13 Feb 2025",
    updates: ["The order of articles in collections is now saved correctly."],
  },
  {
    title: "Load speed improvements",
    date: "12 Feb 2025",
    updates: [
      "The app should now load faster in general.",
      "The images uploaded by users are now optimized for web use and load faster.",
    ],
  },
  {
    title: "Chrome extension improvements for word sync",
    date: "12 Feb 2025",
    updates: [
      "The words get automatically synced to the extension after importing from Duolingo.",
      "This also happens after logging in to the Chrome extension.",
    ],
  },
  {
    title: "German YouTube channels updated",
    date: "12 Feb 2025",
    updates: [
      "Added more YouTube channels to the list of channels for the German language.",
    ],
  },
  {
    title: "PDF import",
    date: "11 Feb 2025",
    updates: [
      "You can now import PDF files into the library.",
      "Find the button next to the 'Content' field in the importer and editor.",
    ],
  },
  {
    title: "Vocabulary export",
    date: "10 Feb 2025",
    updates: [
      "You can now export your vocabulary by copying it to the clipboard or downloading it as a tab-separated text file.",
    ],
  },
  {
    title: "Chat design improvements",
    date: "9 Feb 2025",
    updates: [
      "The mobile version now displays a bit more text per line and the send button is smaller.",
    ],
  },
  {
    title: "Estonian news improvements",
    date: "8 Feb 2025",
    updates: [
      "Cleaned up the content of some article sources to not include the comments.",
    ],
  },
  // Add more recent updates here
];

export const getLatestUpdate = () => {
  return recentReleaseNotes[0];
};

export const getLatestUpdates = (count = 3) => {
  return recentReleaseNotes.slice(0, count);
};
