import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Alert,
  Dropdown,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  MarkerTip,
  FileEarmarkArrowUp,
  QuestionCircle,
} from "react-bootstrap-icons";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LibraryEditor() {
  const context = React.useContext(AuthContext);
  const { t } = useTranslation();
  const { id } = useParams(); // Get the 'id' parameter from the URL
  const navigate = useNavigate();

  const [contentTitle, setContentTitle] = useState("");
  const [contentBody, setContentBody] = useState("");
  const [privacySetting, setPrivacySetting] = useState("private");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [importSuccessMessage, setImportSuccessMessage] = useState("");
  const isEditMode = id !== undefined; // Determine if the component is in edit mode or import mode
  const [contentBodyLength, setContentBodyLength] = useState(0); // New state for character count
  const [selectedCategories, setSelectedCategories] = useState([]); // New state for categories
  const [availableCategories, setAvailableCategories] = useState([]); // New state for available categories
  const [coverImage, setCoverImage] = useState(""); // New state for cover image
  const [isUploading, setIsUploading] = useState(false); // State to track upload status
  const [pdfError, setPdfError] = useState("");
  const [pdfSuccessMessage, setPdfSuccessMessage] = useState("");

  useEffect(() => {
    // Fetch categories from the API
    axios
      .get("/api/categories")
      .then((response) => {
        // Sort categories alphabetically by name before setting them
        const sortedCategories = response.data.categories.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setAvailableCategories(sortedCategories);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setError("Error occurred while fetching categories: " + error.message);
      });

    if (isEditMode) {
      // Fetch the article data and populate the form fields
      setIsLoading(true);
      console.log("Trying to fetch article with id: ", id);
      axios
        .get(`/api/articles/${id}`)
        .then((response) => {
          const {
            title = "",
            body = "",
            privacy = "private",
            categories = [], // Fetch categories from the article
            image = "", // Fetch cover image from the article
          } = response.data.article;
          setContentTitle(title);
          setContentBody(body);
          setContentBodyLength(body.length); // Update character count after fetching
          setPrivacySetting(privacy);
          setSelectedCategories(categories); // Set the selected categories
          setCoverImage(image); // Set the cover image
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching article:", error);
          setError("Error occurred while fetching article: " + error.message);
          setContentTitle(""); // Set default values
          setContentBody("");
          setContentBodyLength(0); // Reset character count
          setPrivacySetting("private");
          setSelectedCategories([]); // Reset selected categories
          setCoverImage(""); // Reset cover image
          setIsLoading(false);
        });
    }
  }, [id, isEditMode]);

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleTitleChange = (event) => {
    setContentTitle(event.target.value);
  };

  const handleBodyChange = (event) => {
    setContentBody(event.target.value);
    setContentBodyLength(event.target.value.length); // Update character count
  };

  const handlePrivacyChange = (event) => {
    setPrivacySetting(event.target.value);
  };

  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;
    setSelectedCategories((prevSelected) =>
      checked
        ? [...prevSelected, value]
        : prevSelected.filter((category) => category !== value)
    );
  };

  const handleImageUpload = async (event) => {
    setIsUploading(true); // Set uploading state to true
    const formData = new FormData();
    formData.append("coverImage", event.target.files[0]);

    if (isEditMode) {
      formData.append("articleId", id);
    }

    try {
      const response = await axios.post(
        `/api/user/articles/image-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setCoverImage(response.data.path); // Update cover image path
    } catch (error) {
      console.error("Error uploading image:", error);
      setError("Error occurred while uploading image: " + error.message);
    } finally {
      setIsUploading(false); // Set uploading state to false
    }
  };

  const fixLineBreaks = (text) => {
    const lines = text.split("\n");
    let fixedText = "";

    for (let i = 0; i < lines.length; i++) {
      let line = lines[i].trim();
      if (!line) {
        // Preserve paragraph breaks
        fixedText += "\n\n";
        continue;
      }

      // Check if this line is a Section Header (Title Case words or starts with a number)
      const isSectionHeader =
        /^[A-Z][a-z]+( [A-Z][a-z]+)*$/.test(line) || /^\d+[\.\)]/.test(line);

      // Keep section headers separate
      if (isSectionHeader) {
        fixedText += "\n\n" + line + "\n\n";
        continue;
      }

      // Check if this line is a bullet point or list item (starts with a number or "-")
      const isListItem = /^(\d+[\.\)]|[-•])/.test(line);
      if (isListItem) {
        fixedText += "\n" + line;
        continue;
      }

      // Merge normal sentences if previous line didn't end properly
      if (
        i > 0 &&
        !lines[i - 1].endsWith(".") &&
        !lines[i - 1].endsWith("?") &&
        !lines[i - 1].endsWith("!") &&
        !lines[i - 1].endsWith(":") &&
        !line.match(/^[A-Z]/) && // Current line does not start with a capital letter
        !line.match(/^\d+\./) // Avoid breaking numbered lists
      ) {
        fixedText += " " + line;
      } else {
        fixedText += "\n" + line;
      }
    }

    return fixedText.trim();
  };

  const handleFormatChange = (format) => {
    if (format === "double-line-breaks") {
      // Replace only single line breaks with double line breaks
      setContentBody(contentBody.replace(/([^\n])\n([^\n])/g, "$1\n\n$2"));
    } else if (format === "fix-line-breaks") {
      setContentBody(fixLineBreaks(contentBody));
    }
  };

  const handlePDFUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Reset the file input so the same file can be selected again
    event.target.value = "";

    // Check if there's existing content and confirm overwrite
    if (contentBody.trim()) {
      if (
        !window.confirm(
          t("library:editor_file_overwrite_warning") ||
            "This will overwrite your current content. Are you sure?"
        )
      ) {
        return;
      }
    }

    setIsLoading(true);
    setPdfError("");
    setPdfSuccessMessage("");

    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "language",
      context.getSelectedLanguagePair().language_learning.code
    ); // Add language to formData

    try {
      const response = await axios.post("/api/user/parse-file", formData);
      setContentBody(response.data.text);
      setContentBodyLength(response.data.text.length);

      // Optionally set the title if PDF has one and title is empty
      if (response.data.metadata?.info?.Title && !contentTitle) {
        setContentTitle(response.data.metadata.info.Title);
      }

      setPdfSuccessMessage(
        t("library:editor_file_import_success") || "File imported successfully"
      );
    } catch (error) {
      setPdfError(
        t("library:editor_file_import_error") ||
          "Error importing file: " +
            (error.response?.data?.message || error.message)
      );
    } finally {
      setIsLoading(false);
    }
  };

  const validateInput = () => {
    if (contentTitle.length < 4 || contentTitle.length > 100) {
      setError("Title must be between 4 and 100 characters.");
      return false;
    }

    if (contentBody.length < 10) {
      setError("Content body must be at least 10 characters.");
      return false;
    }

    if (isEditMode && contentBody.length > 20000) {
      setError("Content body must not exceed 20,000 characters in edit mode.");
      return false;
    }

    return true;
  };

  const splitContent = (content) => {
    const articles = [];
    let remainingContent = content;
    console.log("Starting content split. Total length:", content.length);

    while (remainingContent.length > 0) {
      let splitIndex = 20000;

      if (remainingContent.length > 20000) {
        // Try to find the last line break before 20k
        const lastLineBreak = remainingContent
          .substring(0, 20000)
          .lastIndexOf("\n");
        console.log("Found last line break at:", lastLineBreak);

        // Try to find the last period + space before 20k
        const lastPeriod = remainingContent
          .substring(0, 20000)
          .lastIndexOf(". ");
        console.log("Found last period at:", lastPeriod);

        // Try to find the last space before 20k
        const lastSpace = remainingContent.substring(0, 20000).lastIndexOf(" ");
        console.log("Found last space at:", lastSpace);

        // Always use the first valid break point we find, in order of priority
        if (lastLineBreak > 0) {
          splitIndex = lastLineBreak + 1;
          console.log("Using line break split at:", splitIndex);
        } else if (lastPeriod > 0) {
          splitIndex = lastPeriod + 2;
          console.log("Using period split at:", splitIndex);
        } else if (lastSpace > 0) {
          splitIndex = lastSpace + 1;
          console.log("Using space split at:", splitIndex);
        }
        console.log("Final split index chosen:", splitIndex);
      }

      const chunk = remainingContent.substring(0, splitIndex).trim();
      console.log("Chunk length:", chunk.length);
      console.log("Chunk preview:", chunk.substring(0, 100) + "...");
      console.log(
        "Chunk end preview:",
        "..." + chunk.substring(chunk.length - 100)
      );

      articles.push(chunk);
      remainingContent = remainingContent.substring(splitIndex).trim();
      console.log("Remaining content length:", remainingContent.length);
      console.log("-------------------");
    }

    console.log("Split complete. Number of articles:", articles.length);
    articles.forEach((article, index) => {
      console.log(`Article ${index + 1} length:`, article.length);
    });

    return articles;
  };

  const submitArticle = async () => {
    if (!validateInput()) {
      return;
    }

    setError("");
    setImportSuccessMessage("");
    setIsLoading(true);

    try {
      if (isEditMode) {
        // Handle edit mode (single article update)
        const articleData = {
          title: contentTitle,
          body: contentBody,
          privacy: privacySetting,
          categories: selectedCategories,
          source: "webapp",
          language: context.getSelectedLanguagePair().language_learning.code,
          image: coverImage,
        };

        await axios.put(`/api/user/articles/${id}`, articleData);
        setImportSuccessMessage("Article updated successfully.");
      } else {
        // Handle import mode
        if (contentBody.length <= 20000) {
          // Single article case
          const articleData = {
            title: contentTitle,
            body: contentBody,
            privacy: privacySetting,
            categories: selectedCategories,
            source: "webapp",
            language: context.getSelectedLanguagePair().language_learning.code,
            image: coverImage,
          };

          await axios.post("/api/user/articles", articleData);
          setImportSuccessMessage("Article saved successfully.");
        } else {
          // Multiple articles case
          const contentParts = splitContent(contentBody);

          for (let i = 0; i < contentParts.length; i++) {
            const articleData = {
              title: `${contentTitle} - ${i + 1}`,
              body: contentParts[i],
              privacy: privacySetting,
              categories: selectedCategories,
              source: "webapp",
              language:
                context.getSelectedLanguagePair().language_learning.code,
              image: coverImage,
            };

            await axios.post("/api/user/articles", articleData);
          }

          setImportSuccessMessage(
            `Content split into ${contentParts.length} articles and saved successfully.`
          );
        }
      }

      setIsLoading(false);
      if (!isEditMode) {
        navigate("/library?tab=imported");
      }
    } catch (error) {
      console.error(
        isEditMode ? "Error updating article:" : "Error importing article:",
        error
      );
      setError(
        `Error occurred while ${
          isEditMode ? "updating" : "importing"
        } article: ` + error.message
      );
      setIsLoading(false);
    }
  };

  const deleteArticle = () => {
    const confirmDeletion = window.confirm(
      "Are you sure you want to delete this article?"
    );
    if (!confirmDeletion) {
      return; // Stop the function if the user cancels the action
    }

    setIsLoading(true); // Set loading state
    setError(""); // Clear any previous error messages

    axios
      .delete(`/api/user/articles/${id}`)
      .then((response) => {
        console.log("Article deleted successfully:", response.data);
        setIsLoading(false);
        navigate("/library?tab=imported"); // Redirect to the library after deletion
      })
      .catch((error) => {
        console.error("Error deleting article:", error);
        setError("Error occurred while deleting the article: " + error.message);
        setIsLoading(false);
      });
  };

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
      translate="no"
    >
      <h2>
        {isEditMode ? t("common:edit") : t("common:import")}{" "}
        {isLoading && (
          <Spinner animation="border" role="status" className="ms-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </h2>
      <div className="row mb-3 mt-3">
        <strong>{t("library:editor_cover_image")}</strong>
        <div className="col-auto">
          {isUploading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "150px",
                height: "150px",
                border: "1px solid #dee2e6",
                borderRadius: "0.25rem",
              }}
            >
              <div>
                <Spinner animation="border" role="status" />
                <div className="mt-2">
                  {t("library:editor_uploading_image")}
                </div>
              </div>
            </div>
          ) : (
            coverImage && (
              <img
                src={coverImage}
                alt="Cover"
                className="img-thumbnail"
                style={{
                  maxWidth: "150px",
                  maxHeight: "150px",
                  objectFit: "cover",
                }}
              />
            )
          )}
          <Form.Group controlId="coverImage" className="mt-2">
            <Form.Label>
              {t("library:editor_upload_new_cover_image")}
            </Form.Label>
            <Form.Control type="file" onChange={handleImageUpload} />
          </Form.Group>
        </div>
      </div>

      <Form>
        <Form.Group controlId="formContentImport">
          <Form.Label className="mt-3">
            <strong>{t("library:editor_title")}</strong>
          </Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              value={contentTitle}
              onChange={handleTitleChange}
              placeholder={t("library:editor_title_placeholder")}
            />
          </div>
          <Form.Label className="mt-4 w-100">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="d-flex align-items-end">
                <strong>{t("library:editor_content_title")}</strong>
              </div>
              {!isEditMode && (
                <Form.Group controlId="pdfUpload" className="mt-2 mt-md-0">
                  <Form.Control
                    type="file"
                    accept=".pdf,.txt,.doc,.docx,.rtf,.odt,.sxw,.stw,.wpd,.abw,.pages"
                    onChange={handlePDFUpload}
                    style={{ display: "none" }}
                  />
                  <div className="d-flex align-items-start">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        document.getElementById("pdfUpload").click()
                      }
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                          {t("library:editor_importing")}
                        </>
                      ) : (
                        <>
                          <FileEarmarkArrowUp className="me-2" />
                          {t("library:editor_upload_file_button_text") ||
                            "Upload File"}
                        </>
                      )}
                    </Button>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Supported file types: PDF, TXT, DOC, DOCX, RTF, ODT,
                          SXW, STW, WPD, ABW, Pages
                        </Tooltip>
                      }
                    >
                      <QuestionCircle
                        className="ms-2"
                        style={{ cursor: "help" }}
                      />
                    </OverlayTrigger>
                  </div>
                </Form.Group>
              )}
            </div>
          </Form.Label>

          {isLoading && (
            <Alert variant="info" className="my-3 py-3 px-3">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              {t("library:editor_file_importing_message") ||
                "Importing file..."}
            </Alert>
          )}

          {(pdfError || pdfSuccessMessage) && (
            <div className="my-2">
              {pdfError && (
                <Alert variant="danger" className="py-2 px-3 mb-0">
                  {t("library:editor_file_import_error") ||
                    "Error importing file: " + pdfError}
                </Alert>
              )}
              {pdfSuccessMessage && (
                <Alert
                  variant="success"
                  className="py-3 px-3 mb-0"
                  dismissible
                  onClose={() => setPdfSuccessMessage("")}
                >
                  {t("library:editor_file_import_success") ||
                    "File imported successfully"}
                </Alert>
              )}
            </div>
          )}
          <Form.Control
            as="textarea"
            rows={7}
            value={contentBody}
            onChange={handleBodyChange}
            placeholder={t("library:editor_content_placeholder")}
          />
          <div className="mt-2 d-flex flex-wrap justify-content-between align-items-center">
            <p className="text-muted small mb-0">
              {t("library:editor_characters")}:{" "}
              <span
                style={{ color: contentBodyLength > 20000 ? "red" : "inherit" }}
              >
                {contentBodyLength.toLocaleString()}
              </span>{" "}
              / 20,000
            </p>
            <Dropdown>
              <Dropdown.Toggle
                variant={contentBody.trim() ? "primary" : "light"}
                id="dropdown-basic"
                disabled={!contentBody.trim()}
                size="sm"
              >
                <MarkerTip className="me-2" />
                {t("library:editor_formatting_button")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleFormatChange("double-line-breaks")}
                >
                  {t("library:editor_formatting_add_double_line_breaks")}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleFormatChange("fix-line-breaks")}
                >
                  {t("library:editor_formatting_merge_sentences_button_text")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {!isEditMode && contentBodyLength > 20000 && (
            <Alert variant="warning" className="mt-2">
              {t("library:editor_content_split_warning") ||
                "Note: Very long texts may be automatically split into multiple articles for better reading experience."}
            </Alert>
          )}
          <Form.Label className="mt-4">
            <strong>{t("library:editor_visibility")}</strong>
          </Form.Label>
          <Row>
            <Col md={4}>
              <Form.Select
                value={privacySetting}
                onChange={handlePrivacyChange}
                className="mt-1"
              >
                <option value="private">
                  {t("library:editor_private_setting")}
                </option>
                <option value="public">
                  {t("library:editor_public_setting")}
                </option>
              </Form.Select>
            </Col>
          </Row>
          <Form.Label className="mt-4">
            <strong>{t("library:editor_categories")}</strong>
          </Form.Label>
          {availableCategories.map((category) => (
            <div
              key={category._id}
              className="mt-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedCategories((prevSelected) =>
                  prevSelected.includes(category._id)
                    ? prevSelected.filter((id) => id !== category._id)
                    : [...prevSelected, category._id]
                );
              }}
            >
              <Form.Check
                type="checkbox"
                label={category.name}
                checked={selectedCategories.includes(category._id)}
                onChange={() => {}} // Empty onChange to avoid React warning
                style={{ pointerEvents: "none" }} // Prevents checkbox from capturing clicks
              />
            </div>
          ))}
        </Form.Group>
        {error && (
          <Alert variant="danger" className="mt-2">
            {error}
          </Alert>
        )}
        {importSuccessMessage && (
          <Alert variant="success" className="mt-2">
            {importSuccessMessage}
          </Alert>
        )}
        <Button
          variant="primary"
          onClick={submitArticle}
          disabled={isLoading}
          className="my-5"
        >
          {isLoading
            ? isEditMode
              ? t("library:editor_updating")
              : t("library:editor_importing")
            : isEditMode
            ? t("common:update")
            : t("common:import")}
        </Button>
        {isEditMode && (
          <Link to={"/reader/" + id}>
            <Button variant="dark" className="my-5 ms-3">
              {t("library:editor_open_in_reader")}
            </Button>
          </Link>
        )}
        {isEditMode && (
          <Button
            variant="danger"
            className="my-5 ms-3"
            onClick={deleteArticle}
          >
            {t("common:delete")}
          </Button>
        )}
      </Form>
    </Container>
  );
}

export default LibraryEditor;
