import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Spinner,
  Container,
  Dropdown,
  Form,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { BadgeCc, Translate } from "react-bootstrap-icons";
import PlaceholderImg from "../chalice-150x150.png";
import YouTube from "react-youtube";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import "./VideoCard.css";
import "../pages/Music.css";
import CaptionsModal from "./VideoCardCaptionsModal";
import AnalyticsContext from "../context/AnalyticsContext";
import { useYouTubeAudioTracking } from "../hooks/useYouTubeAudioTracking";


export default function VideoCard({ video, index }) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [captionsProcessing, setCaptionsProcessing] = useState(false);
  const [captionsSuccessMessage, setCaptionsSuccessMessage] = useState(false);
  const [wakeLock, setWakeLock] = useState(null);
  const playerRef = useRef(null);
  const currentTimeRef = useRef(0);
  const lastTimeUpdate = useRef(0);
  const timeTrackingInterval = useRef(null);

  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const analyticsContext = useContext(AnalyticsContext);
  const {
    handleStateChange,
    handlePlaybackRateChange,
    handleSeeking,
    handleSeeked,
    cleanup,
  } = useYouTubeAudioTracking();

  useEffect(() => {
    let wakeLockObj = null;

    const requestWakeLock = async () => {
      try {
        if ("wakeLock" in navigator) {
          // This works for Android and modern browsers
          wakeLockObj = await navigator.wakeLock.request("screen");
          setWakeLock(wakeLockObj);
        }
        // iOS solution
        document.querySelector('meta[name="viewport"]').content +=
          ", interactive-widget=overlays-content";
      } catch (err) {
        console.log(`Wake Lock error: ${err.name}, ${err.message}`);
      }
    };

    if (showModal) {
      requestWakeLock();
    } else {
      // Release wake lock when modal is closed
      if (wakeLock) {
        wakeLock.release();
        setWakeLock(null);
      }
      // Reset viewport meta
      document.querySelector('meta[name="viewport"]').content =
        "width=device-width, initial-scale=1";
    }

    // Cleanup on component unmount
    return () => {
      if (wakeLock) {
        wakeLock.release();
      }
    };
  }, [showModal]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  const openVideoPlayer = (videoId) => () => {
    setShowModal(true);
    trackVideoWatched(videoId, "webapp");
  };

  const closeVideoPlayer = () => {
    setShowModal(false);
  };

  const openCameraModal = () => {
    setShowCameraModal(true);
  };

  const closeCameraModal = () => {
    setShowCameraModal(false);
  };

  const importSubtitles = async (
    e,
    videoId,
    location,
    language_learning_code,
    language_learning_name,
    image_url,
    channel_title,
    channel_id,
    published_at
  ) => {
    e.preventDefault();
    setCaptionsProcessing(true);
    try {
      const response = await axios.post(
        `/api/videos/${videoId}/subtitles/import`,
        {
          location: location,
          language_learning_code: language_learning_code,
          language_learning_name: language_learning_name,
          video_thumbnail_url: image_url,
          channel_title: channel_title,
          channel_id: channel_id,
          published_at: published_at,
          source: "webapp_videos_captions_import",
        }
      );
      // alert("Captions imported successfully.");
      setCaptionsSuccessMessage(true);
      setCaptionsProcessing(false);

      console.log(response.data.message);
    } catch (error) {
      setCaptionsProcessing(false);
      console.error("Error importing video subtitles:", error);
      if (error.response && error.response.data && error.response.data.error) {
        alert(error.response.data.error);
      } else {
        // Fallback to a general error message if the error details are not available
        alert(error.message);
      }
    }
  };

  return (
    <>
      <Col key={index} xs={12} className="mb-4">
        <Card>
          <Card.Body>
            <Row className="flex-column flex-md-row">
              <Col xs={12} md={2}>
                <div
                  style={{
                    width: "100%",
                    paddingBottom: "56.25%",
                    position: "relative",
                  }}
                  className="mb-3 mb-md-0"
                >
                  <Link onClick={openVideoPlayer(video.videoId)}>
                    <Card.Img
                      variant="top"
                      src={video.thumbnails.default.url || PlaceholderImg}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: video.thumbnails.default.url
                          ? "cover"
                          : "scale-down",
                      }}
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite callback loop
                        e.target.src = PlaceholderImg; // Replace with placeholder image
                        e.target.style.objectFit = "scale-down"; // Change objectFit to scale-down
                      }}
                    />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={10}>
                <Card.Title>
                  <Link
                    onClick={openVideoPlayer(video.videoId)}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {video.title}
                  </Link>
                </Card.Title>

                <Card.Subtitle className="mb-2 text-muted">
                  {video.channelTitle} |{" "}
                  <span className="text-none">
                    {video.publishedAt.slice(0, 10)}
                  </span>
                </Card.Subtitle>
                <Card.Text>
                  {/* converts to user's local timezone */}
                  {/* {new Date(article.date_imported).toLocaleString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          }
                        )} */}
                </Card.Text>
                <Button
                  onClick={openVideoPlayer(video.videoId)}
                  variant="outline-dark"
                  className="btn-sm me-2 mt-2"
                >
                  {t("library:watch")}
                </Button>
                <Link
                  to={`https://www.youtube.com/watch?v=${video.videoId}`}
                  onClick={() => {
                    trackVideoWatched(video.videoId, "webapp");
                  }}
                  target="_blank"
                >
                  <Button
                    // href={`/reader/${article._id}`}
                    variant="outline-dark"
                    className="btn-sm me-2 mt-2"
                  >
                    {t("library:watch_on_youtube")}
                  </Button>
                </Link>
                {/* <Button
                  onClick={(e) =>
                    importSubtitles(
                      e,
                      video.videoId,
                      "webapp",
                      context.getSelectedLanguagePair().language_learning.code,
                      context.getSelectedLanguagePair().language_learning.name,
                      video.thumbnails.default.url,
                      video.channelTitle,
                      video.channelId,
                      video.publishedAt
                    )
                  }
                  disabled={captionsProcessing}
                  variant="outline-dark"
                  className="btn-sm me-2 mt-2"
                >
                  {captionsProcessing ? (
                    <>
                      <Spinner animation="grow" size="sm" className="me-2" />{" "}
                      {t("library:import_captions_in_progress")}...
                    </>
                  ) : (
                    <>
                      <BadgeCc className="me-2" />{" "}
                      {t("library:import_captions")}
                    </>
                  )}
                </Button> */}
                {/* {context.email &&
                  (context.email.includes("atjackiejohns") ||
                    context.email.includes("marj")) && (
                    <Button
                      onClick={openCameraModal}
                      variant="outline-dark"
                      className="btn-sm me-2 mt-2"
                    >
                      <Translate className="me-2" /> View
                    </Button>
                  )} */}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Modal show={showModal} onHide={closeVideoPlayer} size="lg" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="video-responsive">
            <YouTube
              videoId={video.videoId}
              opts={{
                playerVars: {
                  cc_load_policy: 1,
                  cc_lang_pref:
                    context.getSelectedLanguagePair().language_learning.code,
                  hl: context.getSelectedLanguagePair().language_learning.code,
                  rel: 0,
                  modestbranding: 1,
                  playsinline: 1,
                },
                width: "100%",
                height: "100%",
              }}
              className="youtube-player"
              onReady={(event) => {
                playerRef.current = event.target;
              }}
              onStateChange={handleStateChange}
              onPlaybackRateChange={handlePlaybackRateChange}
              onSeeking={handleSeeking}
              onSeeked={handleSeeked}
            />
          </div>

          <div className="d-flex justify-content-center mb-2 my-3">
            {t("library:video_card_statistics_note")}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={captionsSuccessMessage}
        centered={true}
        onHide={() => {
          setCaptionsSuccessMessage(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <BadgeCc className="me-2 mb-1" />
            {t("library:captions_import_success_title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("library:captions_import_success_description")}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              navigate("/library?tab=imported");
              setCaptionsSuccessMessage(false);
            }}
          >
            {t("library:show_it_in_library")} &#8594;
          </Button>
          {/* <Button
            variant="success"
            onClick={() => {
              navigate("/reader/" + simplificationMessage);
            }}
          >
            Open the new article
          </Button> */}
        </Modal.Footer>
      </Modal>
      {/* <CaptionsModal
        show={showCameraModal}
        onHide={closeCameraModal}
        video={video}
        context={context}
      /> */}
    </>
  );
}

const trackVideoWatched = async (videoId, location) => {
  try {
    const response = await axios.post(`/api/videos/${videoId}`, {
      location: location,
    });

    console.log(response.data.message);
  } catch (error) {
    console.error("Error tracking video watch:", error);
  }
};
