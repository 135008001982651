import React, { useState, useContext } from "react";
import { Button, Form, Alert, Collapse, Table } from "react-bootstrap";
import { InfoCircle, Youtube } from "react-bootstrap-icons";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import AuthContext from "../context/AuthContext";

function VocabularyImporter() {
  const { t } = useTranslation();
  const context = useContext(AuthContext);
  const [inputText, setInputText] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [importSuccessMessage, setImportSuccessMessage] = useState("");
  const [openAnki, setOpenAnki] = useState(false);
  const [openDuolingo, setOpenDuolingo] = useState(false);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const validateLine = (line) => {
    // const separator = line.includes(",") ? "," : "\t"; // find the seperator (whichever is found first)
    // const parts = line.split(separator);
    const parts = line.split("\t"); // Always split by tabs

    // Ensure there's at least a word and a translation
    if (parts.length < 2) {
      return false;
    }
    const [word, translation] = parts;
    if (word.length > 50 || translation.length > 50) {
      return false;
    }
    return true;
  };

  const parseStrength = (value) => {
    const number = parseInt(value, 10);
    return !isNaN(number) && number >= 0 && number <= 5 ? number : 0;
  };

  const importVocabulary = () => {
    setError("");
    const lines = inputText.split("\n");

    // Check if the first line is empty or contains column headers
    // loop throw the lines and delete empty lines
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].trim() === "") {
        lines.splice(i, 1);
        i--;
      }
    }

    // if the first line contains "word" or "translation" or "strength", remove it
    if (/word|translation|strength/i.test(lines[0].toLowerCase())) {
      lines.shift();
    }

    const vocabulary = [];

    for (const line of lines) {
      if (!validateLine(line)) {
        setError(
          "Error: Each line must have a word and a translation (each up to 100 characters), followed by optional additional values. Also, please make sure you didn't actually keep the column headers in the first line.\n\nThis the line that caused the error: \n\n" +
            line
        );

        console.log("Error: Invalid import line:", line);
        return;
      }

      const parts = line.split("\t"); // Always split by tabs
      const word = parts[0];
      const translation = parts[1];
      const strength =
        parts.length > 2 ? parseStrength(parts[parts.length - 1]) : 0;

      vocabulary.push({ word, translation, strength });
    }

    setIsLoading(true);

    console.log("Trying to import vocabulary:", vocabulary);

    axios
      .put("/api/user/vocabulary/import", {
        words: vocabulary,
        language_pair_id: context.getSelectedLanguagePair()._id,
      })
      .then((response) => {
        console.log("Import successful:", response.data);
        setImportSuccessMessage(
          t("vocabulary:import_success_message", {
            added: response.data.added,
            updated: response.data.updated,
          })
        );
        setInputText("");
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error importing vocabulary:", error);
        setError(
          t("vocabulary:import_error_importing_vocabulary") + error.message
        );
        setIsLoading(false);
      });
  };

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
      translate="no"
    >
      <h2>{t("vocabulary:import_vocabulary_title")}</h2>
      <Form>
        <Form.Group controlId="formVocabularyImport">
          <Form.Label>
            <p>
              <strong>
                {t("vocabulary:import_vocabulary_instructions_1")}
              </strong>{" "}
              {t("vocabulary:import_vocabulary_instructions_2")}
            </p>
            <h5>{t("vocabulary:import_sample_spreadsheet_title")}</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{t("vocabulary:word_column_title")}</th>
                  <th>{t("vocabulary:translation_column_title")}</th>
                  <th>
                    <span style={{ fontWeight: "400" }}>
                      {t("vocabulary:strength_column_title")}*
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>la casa</td>
                  <td>house</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>la stanza</td>
                  <td>room</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>el edificio</td>
                  <td>building</td>
                  <td>5</td>
                </tr>
              </tbody>
            </Table>
            <p>
              {t(
                "vocabulary:import_sample_spreadsheet_strength_column_tooltip_text"
              )}
            </p>
            <p>
              {t("vocabulary:import_things_to_keep_in_mind_title")}
              <ul>
                <li>
                  {t("vocabulary:import_things_to_keep_in_mind_list_item_1")}
                </li>
                <li>
                  {t("vocabulary:import_things_to_keep_in_mind_list_item_2")}
                </li>
                <li>
                  {t("vocabulary:import_things_to_keep_in_mind_list_item_3")}
                </li>
              </ul>
            </p>
            <div className="d-inline-block me-2">
              <Button
                onClick={() => setOpenAnki(!openAnki)}
                aria-controls="AnkiInstructions"
                aria-expanded={openAnki}
                variant="secondary"
                className="d-flex align-items-center"
              >
                <InfoCircle className="me-1" />{" "}
                {t("vocabulary:import_anki_instructions_button_text")}
              </Button>
              <Collapse in={openAnki}>
                <div id="AnkiInstructions" className="mt-2">
                  <h5>{t("vocabulary:import_anki_instructions_title")}</h5>
                  <p>{t("vocabulary:import_anki_instructions_description")}</p>
                </div>
              </Collapse>

              <Button
                onClick={() => setOpenDuolingo(!openDuolingo)}
                aria-controls="DuolingoInstructions"
                aria-expanded={openDuolingo}
                variant="secondary"
                className="d-flex align-items-center mt-2"
              >
                <InfoCircle className="me-1" />{" "}
                {t("vocabulary:import_duolingo_instructions_button_text")}
              </Button>
              <Collapse in={openDuolingo}>
                <div id="DuolingoInstructions" className="mt-2">
                  <h5>{t("vocabulary:import_duolingo_instructions_title")}</h5>
                  <p>
                    <Trans
                      i18nKey="vocabulary:import_duolingo_instructions_description"
                      components={[
                        <a
                          href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff"
                          target="_blank"
                          rel="noopener noreferrer"
                        />,
                        <a
                          href="https://www.duolingo.com/practice-hub/words"
                          target="_blank"
                          rel="noopener noreferrer"
                        />,
                      ]}
                    />
                  </p>
                  <div className="d-flex justify-content-left">
                    <Button
                      variant="success"
                      className="d-flex align-items-center"
                      href="https://youtu.be/izVMe2hswDI"
                      target="_blank"
                    >
                      <Youtube className="me-2" />
                      Duolingo - {t(
                        "vocabulary:chrome_extension_alert_tutorial_button_text"
                      )}
                    </Button>
                  </div>
                </div>
              </Collapse>
            </div>
            <p></p>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={7}
            value={inputText}
            onChange={handleInputChange}
            placeholder={t("vocabulary:import_textarea_placeholder")}
          />
        </Form.Group>
        {error && (
          <Alert variant="danger" className="mt-3 mb-0">
            {error}
          </Alert>
        )}
        {importSuccessMessage && (
          <Alert variant="success" className="mt-3 mb-0">
            {importSuccessMessage}
          </Alert>
        )}
        <Button
          variant="primary"
          onClick={importVocabulary}
          disabled={isLoading}
          className="my-3"
        >
          {isLoading ? t("common:importing") : t("common:import")}
        </Button>
      </Form>
    </Container>
  );
}

export default VocabularyImporter;
