import React, { useState, useEffect } from "react";
import { Alert, Button, Modal, Badge } from "react-bootstrap";
import { Youtube } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function DuolingoDownloadVocabularyMessage() {
  const [show, setShow] = useState(true);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    // check if utm_medium = duolingo_ninja_uninstall
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramsObj = {};
    for (const [key, value] of urlParams.entries()) {
      paramsObj[key] = value;
    }
    if (
      paramsObj["utm_campaign"] === "duolingo_ninja_download_vocabulary_page"
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  if (show) {
    return (
      <>
        {/* center in the middle horizontally */}
        <div className="d-flex justify-content-center mb-5">
          <Alert
            variant="success"
            onClose={() => setShow(false)}
            dismissible
            style={{ maxWidth: "1000px" }}
          >
            <Alert.Heading>Download your Duolingo vocabulary</Alert.Heading>
            <p>
              Use <strong>Lingo Champion's browser extension</strong> to:
            </p>
            <ul>
              <li>{t("vocabulary:chrome_extension_alert_list_item_2")}</li>
              <li>{t("vocabulary:chrome_extension_alert_list_item_1")}</li>
              {/* <li>{t("vocabulary:chrome_extension_alert_list_item_3")}</li> */}
              <li>
                {t("vocabulary:chrome_extension_alert_list_item_4")}
                <sup>
                  <Badge bg="primary" text="light" className="ms-1">
                    {t("common:new")}
                  </Badge>
                </sup>
              </li>
            </ul>

            <p className="mb-0">
              {t("vocabulary:chrome_extension_alert_description")}
            </p>
            <hr />
            <div className="d-flex gap-2">
              <Button
                variant="primary"
                href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff?hl=en-GB"
                target="_blank"
              >
                Download Extension
              </Button>
              <Button
                variant="success"
                className="d-flex align-items-center"
                href="https://youtu.be/izVMe2hswDI"
                target="_blank"
              >
                <Youtube className="me-2" />
                {t("vocabulary:chrome_extension_alert_tutorial_button_text")}
              </Button>
            </div>
          </Alert>
        </div>

        <Modal
          show={showVideoModal}
          onHide={() => setShowVideoModal(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>How to Download Duolingo Vocabulary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="ratio ratio-16x9">
              <iframe
                src="https://www.youtube.com/embed/YOUR_VIDEO_ID"
                title="How to Download Duolingo Vocabulary"
                allowFullScreen
              ></iframe>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
