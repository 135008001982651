import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import QuickTranslationTooltip from "../Components/QuickTranslationTooltip.js";
// import table landscape icon
import {
  ThreeDots,
  Bug,
  InfoCircle,
  BoxArrowInRight,
  Send,
} from "react-bootstrap-icons";
import "./Music.css";
import "./Chat.css";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext.js";

// import articleData from "./Reader-example.json";
// import knownWords from "./Reader-known-words.json";
import { useLocation } from "react-router-dom";

import {
  splitIntoTokens,
  checkTranslationPanelProp,
  escapeHTML,
  sendBugReport,
  openSupportCenter,
} from "../utils/ReaderUtils.js";

import axios from "axios";

// import css for Reader
import "./Reader.css";
import TranslationPanel from "./TranslationPanel.js";
import { checkIfWordIsKnown } from "../utils/ReaderUtils.js";

// BUG: if you finish the article and navigate back to Dashboard and read the same article, the words are kept in memory and the words are not marked from the beginning
let tokens = [];
/* 
  {
    _id: "id",
    date_added: "date",
    word: "word",
    known: true,
    is_title: true,
    is_last_title_word: true,
    is_not_a_word: true,
    is_number: false,
    translation: "translation",
    strength: 2,

    is_number: true,
    is_punctuation: true,
  }
*/
function App() {
  const { t } = useTranslation();
  const context = React.useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  // Extract articleId from URL parameters
  const params = new URLSearchParams(location.search);
  const articleId = params.get("articleId");

  // const [tokens, setTokens] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [knownWordsArray, setKnownWordsArray] = useState(null);
  const [knownWordsAtTheStart, setKnownWordsAtTheStart] = useState([]);

  const [selectedWord, setSelectedWord] = useState(null);
  const [wordForQuickLookup, setWordForQuickLookup] = useState(null);
  const [wordForFullLookup, setWordForFullLookup] = useState(null);
  // TODO: need to create a separate setting like this: context.settings?.quick_lookup_in_audio_player
  const quickLookupRef = useRef(context.settings?.quick_lookup_in_chat);
  const expandAIExplanationBoxRef = useRef(
    context.settings?.expand_ai_explanation_box
  );
  // State variables to manage the text content
  const [selectedWordOriginalTextState, setSelectedWordOriginalTextState] =
    useState({});

  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const [currentThreadId, setCurrentThreadId] = useState(null);

  const [loadingAIResponse, setLoadingAIResponse] = useState(!!articleId);

  const inputRef = useRef(null); // Create a ref for the input field

  useEffect(() => {
    if (!loadingAIResponse) {
      inputRef.current?.focus(); // Focus the input field when not loading
    }
  }, [loadingAIResponse]); // Dependency on loadingAIResponse

  useEffect(() => {
    if (messages.length > 0) {
      console.log("Messages changed:", messages);
      const processedMessages = processMessages(messages);
      console.log("Processed messages:", processedMessages);
      tokens = processedMessages.flatMap((msg) => msg.words);
      console.log("All tokens:", tokens);
      insertWords(0);
      scrollToBottom();
    }
  }, [messages, knownWordsArray]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputValue.trim() || !currentThreadId || loadingAIResponse) return;

    const newMessage = {
      text: inputValue.trim(),
      sender: "user",
      id: Date.now(),
    };

    setMessages((prev) => [...prev, newMessage]);
    setInputValue("");
    setLoading(true);
    setLoadingAIResponse(true);

    try {
      const response = await axios.post("/api/chat/send-message", {
        threadId: currentThreadId,
        message: newMessage.text,
      });

      // Parse the reply string to extract the answer and correction
      const replyData = JSON.parse(response.data.reply);
      const aiResponseText = replyData.answer;
      const correction = replyData.correction;

      // Update the previous user message with the correction
      if (correction) {
        setMessages((prev) =>
          prev.map((msg) => {
            if (msg.id === newMessage.id) {
              return { ...msg, correction };
            }
            return msg;
          })
        );
      }

      // Add AI's response without the correction
      const aiResponse = {
        text: aiResponseText,
        sender: "ai",
        id: Date.now(),
      };
      setMessages((prev) => [...prev, aiResponse]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
      setLoadingAIResponse(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Prevents default context menu on right-click or long-press (this should include mobile text selection)
  // NOTE: it does not work on Android because onTouchEnd somehow gets triggered differently (after closing the default context menu - preventDefault doesn't work)
  const handleContextMenu = (e) => {
    e.preventDefault();

    // Check if the device is Android
    const isAndroid = /Android/i.test(navigator.userAgent);

    if (isAndroid) {
      // Check if the user is currently touching the screen
      if (!e.touches || e.touches.length === 0) {
        // BUG: the following hack works but not for Thai because there are no spaces there
        const selection = window.getSelection().toString().trim();
        // if the selection contains multiple words, trigger the handleTextSelection function
        // Otherwise this event gets triggered already when long pressing on the screen
        if (selection) {
          const words = selection
            .split(/\s+/)
            .filter((word) => word.length > 0);
          if (words.length > 1) {
            handleTextSelection();
          }
        }
      }
    }
  };

  // NOTE: this is purely bc tokens is declared outside the component and the values would persist even when the component gets unmounted (moving betw library and reader)
  useEffect(() => {
    if (tokens.length > 0) tokens = [];
  }, []);

  useEffect(() => {
    console.log("settings changed:", context.settings);
  }, [context.settings]);

  useEffect(() => {
    console.log("Quick lookup in chat changed: ", quickLookupRef.current);
  }, [quickLookupRef.current]);

  useEffect(() => {
    console.log("Selected word changed: ", selectedWord);
    if (selectedWord?.word && selectedWord.word.trim() !== "") {
      const wordToPass = { ...selectedWord }; // Ensure we pass a complete copy
      if (quickLookupRef.current) {
        console.log("Passing to quick lookup:", wordToPass);
        setWordForQuickLookup(wordToPass);
      } else {
        console.log("Passing to full lookup:", wordToPass);
        setWordForFullLookup(wordToPass);
      }
    }
  }, [selectedWord]);

  // Function to process a text fragment for phrases and tokens
  function processFragment(fragment, sentence) {
    let phrases = knownWordsArray.filter(
      (obj) => obj && obj.word && obj.word.includes(" ")
    );
    let lowerCaseFragment = fragment.toLowerCase();
    let phraseMatches = [];

    // Find all matches first
    phrases.forEach((phrase) => {
      let lowerCasePhrase = phrase.word.toLowerCase();
      let phraseIndex = lowerCaseFragment.indexOf(lowerCasePhrase);
      while (phraseIndex !== -1) {
        phraseMatches.push({
          index: phraseIndex,
          length: phrase.word.length,
          phrase: phrase.word,
        });
        phraseIndex = lowerCaseFragment.indexOf(
          lowerCasePhrase,
          phraseIndex + 1
        );
      }
    });

    // Sort the matches by their start index; in case of a tie, longer phrases first
    phraseMatches.sort((a, b) => a.index - b.index || b.length - a.length);

    let processedFragments = [];
    let currentIndex = 0;

    // Process the matches in order and handle overlaps
    phraseMatches.forEach((match) => {
      if (match.index >= currentIndex) {
        if (currentIndex < match.index) {
          processedFragments.push(
            ...splitIntoTokens(
              fragment.substring(currentIndex, match.index),
              sentence
            )
          );
        }
        processedFragments.push({
          token: fragment.substring(match.index, match.index + match.length),
          sentence,
        });
        currentIndex = match.index + match.length;
      }
    });

    // Process any remaining text
    if (currentIndex < fragment.length) {
      processedFragments.push(
        ...splitIntoTokens(fragment.substring(currentIndex), sentence)
      );
    }

    return processedFragments;
  }

  // Function to split text by URLs and process each fragment
  function splitByURLsAndProcess(sentence) {
    const urlRegex = /https?:\/\/[^\s]+/g;
    let splitText = sentence.split(urlRegex);
    let urlMatches = sentence.match(urlRegex) || [];

    let finalTokens = [];
    splitText.forEach((fragment, index) => {
      finalTokens.push(...processFragment(fragment, sentence));
      if (urlMatches[index]) {
        finalTokens.push({ token: urlMatches[index], sentence });
      }
    });

    return finalTokens;
  }

  // Function to process the entire text
  function processText(text) {
    console.log("processText: Processing text:", text); // Debugging statement

    // Replace double line breaks with a unique placeholder
    const doubleLineBreakPlaceholder = "__DOUBLE_LINE_BREAK__";
    const singleLineBreakPlaceholder = "__SINGLE_LINE_BREAK__";

    text = text.replace(/\n{2}/g, doubleLineBreakPlaceholder);
    text = text.replace(/\n/g, singleLineBreakPlaceholder);

    // Split the text into fragments by placeholders
    let fragments = text.split(
      new RegExp(
        `(${doubleLineBreakPlaceholder}|${singleLineBreakPlaceholder})`
      )
    );

    console.log("processText: Fragments found:", fragments); // Debugging statement

    if (fragments.length === 0) {
      console.log("processText: No fragments to process.");
      return [];
    }

    // Process each fragment separately
    return fragments.flatMap((fragment) => {
      if (fragment === doubleLineBreakPlaceholder) {
        return [{ token: "\n\n", sentence: "" }];
      } else if (fragment === singleLineBreakPlaceholder) {
        return [{ token: "\n", sentence: "" }];
      } else {
        return splitByURLsAndProcess(fragment);
      }
    });
  }

  // fetch known words from the backend
  useEffect(() => {
    const fetchAndSetKnownWords = async () => {
      try {
        await context.fetchKnownWords();
        setKnownWordsAtTheStart(context.known_words || []);
      } catch (error) {
        console.error("Error fetching known words:", error);
      }
    };

    fetchAndSetKnownWords();
  }, []);

  // create useEffect for SelectedWordOriginalTextState
  useEffect(() => {
    console.log(
      "SelectedWordOriginalTextState changed: ",
      selectedWordOriginalTextState
    );
  }, [selectedWordOriginalTextState]);

  useEffect(() => {
    console.log("Known words array changed in the context.");
    setKnownWordsArray(context.known_words || []);
  }, [context.known_words]);

  // function toggleFullScreen() {
  //   setIsFullScreen(!isFullScreen);
  // }

  async function addArticleToUserLibrary(
    articleId,
    percentComplete = 0,
    wordsRead = 0
  ) {
    // get the selected language pair ID from the context
    let languagePairId;
    if (context.language_pairs) {
      for (const pair of context.language_pairs) {
        console.log("Selected language pair (in Reader): ", pair);
        if (pair.is_selected) {
          languagePairId = pair._id;
          break;
        }
      }
    }
    console.log("Language pair ID is (in Reader): ", languagePairId);

    try {
      console.log("Trying to add article to user library.");
      const response = await axios.post("/api/user/add-article-to-library", {
        article_id: articleId,
        language_pair_id: languagePairId,
        percent_complete: percentComplete,
        words_read: wordsRead,
      });
      console.log("Response from adding article to user library: ", response);
    } catch (error) {
      console.error(
        "There was a problem with adding the article to the user library:",
        error
      );
    }
  }

  // BUG: swiping on mobile doesn't work properly - it's impossible to select text without swiping
  const [startX, setStartX] = useState(null);

  const handleTouchStart = (e) => {
    // e.stopPropagation();
    setStartX(e.touches[0].clientX);
  };

  // close the translations panel when the user swipes down on it
  // NOTE: this works even when the panel is not full screen
  const [startY, setStartY] = useState(null); // New state variable for Y-coordinate

  const handleTouchStartTranslationsPanel = (e) => {
    setStartY(e.touches[0].clientY); // Store the Y-coordinate at touch start
  };

  const handleTouchMoveTranslationsPanel = (e) => {
    // if a selection was made, don't count it as a swipe
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      return; // Exit function if text is selected
    }

    if (startY === null) {
      return;
    }

    const yDiff = startY - e.touches[0].clientY; // Calculate Y-coordinate difference

    // if the user is scrolling down more than 50% of the viewport height, close the translations panel
    // originally used just 500 in absolute units but it's less reliable bc screen sizes vary
    if ((Math.abs(yDiff) / document.documentElement.clientHeight) * 100 > 50) {
      // Threshold for minimal swipe distance
      if (yDiff < 0) {
        closeTranslationsPanel(); // Call closeTranslationsPanel on swipe down
        // hide translations panel
        document.getElementById("translations").style.display = "none";
      }

      setStartY(null); // Reset startY so that the swipe is only counted once
    }
  };

  // handle mouse clicks and touch events and selection
  useEffect(() => {
    // Get the element by ID
    const textContentElement = document.getElementById("textContent");

    // Add event listeners if the element exists
    // NOTE: can't add to document because it doesn't work on mobile - translation panel clicks would be counted as well
    if (textContentElement) {
      textContentElement.addEventListener("mouseup", handleTextSelection);
      textContentElement.addEventListener("touchend", handleTextSelection); // for touch screens
    }

    // Cleanup function to remove event listeners
    return () => {
      if (textContentElement) {
        document.removeEventListener("mouseup", handleTextSelection);
        document.removeEventListener("touchend", handleTextSelection); // for touch screens
      }
    };
  }, []);

  function handleTextSelection(e) {
    const selection = window.getSelection();
    console.log("SELECTION: ", selection);
    console.log("SELECTION EVENT: ", e);

    // if the click is on the translation panel, do nothing
    let anchorNode = selection.anchorNode;
    if (anchorNode && anchorNode.nodeType === Node.TEXT_NODE) {
      anchorNode = anchorNode.parentNode;
    }

    const propExists = checkTranslationPanelProp(anchorNode);
    if (anchorNode) {
      console.log(`Does --translation-panel exist on the parent?`, propExists);
      if (propExists) {
        return;
      }
    }

    clearTranslationPanelSelectedWord();

    // phrase selection
    if (selection.rangeCount > 0 && selection.type === "Range") {
      const range = selection.getRangeAt(0);
      const startContainer = range.startContainer;
      const endContainer = range.endContainer;

      // Get the start and end parent elements
      const startElement = startContainer.parentElement;
      const endElement = endContainer.parentElement;

      // Check if we're dealing with correction text
      const isCorrection = startElement.dataset.isCorrection === "true";

      // Get the indices
      const startWordIndex = parseInt(startElement.dataset.index);
      const endWordIndex = parseInt(endElement.dataset.index);

      const selectedText = selection.toString().trim();
      const words = selectedText.split(/\s+/).filter((word) => /\w/.test(word));
      console.log("WORDS: ", words.length, words);

      if (words.length > 10) {
        setTimeout(() => {
          selection.removeAllRanges();
        }, 100);
        return console.log(
          "The selection contains too many words - not sending it to translation."
        );
      }

      let selectedPhrase = { word: "", is_phrase: false };

      // Select all words between start and end indices
      for (let i = startWordIndex; i <= endWordIndex; i++) {
        const selector = isCorrection
          ? `[data-is-correction="true"][data-index="${i}"]`
          : `[data-index="${i}"]:not([data-is-correction])`;

        const wordElement = document.querySelector(selector);

        if (wordElement) {
          selectedPhrase.word += wordElement.innerText + " ";
          selectedPhrase.is_phrase = true;
          wordElement.classList.add("highlighted");
        } else if (!wordElement) {
          console.warn(`Element with data-index="${i}" not found.`);
        }
      }

      setTimeout(() => {
        selection.removeAllRanges();
      }, 100);
      document.getElementById("textContent").focus();

      selectedPhrase.word = selectedPhrase.word.trim();
      selectedPhrase.word = removePunctuation(selectedPhrase.word);
      selectedPhrase.sentence = startElement.getAttribute("data-sentence");
      console.log("Selected phrase: ", selectedPhrase);

      setSelectedWord(selectedPhrase);
    } else {
      setSelectedWordOriginalTextState("");
    }
  }

  // Function to remove punctuation from the start and end of a string
  // TODO: should remove from everywhere probably
  function removePunctuation(str) {
    return str.replace(/^\p{P}+|\p{P}+$/gu, "");
  }

  // BUG: when clicking on a blue word after a blue word was clicked, the translation doesn't show up
  // it shows up when clicking on a known first though

  function handleWordClick(event) {
    const clickedWordIndex = parseInt(event.target.dataset.index);
    const clickedWord = tokens[clickedWordIndex];
    console.log("Clicked word element:", event.target);
    console.log("Clicked word from tokens:", clickedWord);

    // Add highlighting
    event.target.classList.add("highlighted");

    // Create a complete word object with all necessary properties
    const wordData = {
      _id: clickedWord._id || null, // Ensure _id is always present
      word: clickedWord.word,
      sentence: clickedWord.sentence,
      known: clickedWord.known || false,
      translation: clickedWord.translation,
      strength: clickedWord.strength || 0,
      is_not_a_word: clickedWord.is_not_a_word || false,
      is_number: clickedWord.is_number || false,
      srs_due_date: clickedWord.srs_due_date,
      date_added: clickedWord.date_added,
    };

    console.log("Word data being set:", wordData);
    setSelectedWord(wordData);
  }

  function closeTranslationsPanel() {
    document.getElementById("translations").style.display = "none";
    clearTranslationPanelSelectedWord();
  }

  // NOTE: even if I refresh the page, I still have to deal with word highlighting as well somehow
  function refreshPage() {
    insertWords(0);
  }

  function markUnknownWordsAsKnown() {
    // loop through the words between startingWordIndex and endingWordIndex
    let wordsToUpdate = [];
    for (let i = 0; i < tokens.length; i++) {
      // check if the word is a word and unknown
      if (tokens[i].is_not_a_word || tokens[i].known) {
        continue;
      }
      // add the word to the wordsToUpdate array
      // REVIEW: not sure if this is needed anymore - maybe the backend query somehow handles it anyway
      tokens[i].known = true;
      tokens[i].strength = 5;
      tokens[i].translation = undefined;
      wordsToUpdate.push(tokens[i]);
    }
    // remove duplicates from the wordsToUpdate array
    wordsToUpdate = wordsToUpdate.filter(
      (word, index, self) =>
        index === self.findIndex((w) => w.word === word.word)
    );
    console.log("WORDS TO UPDATE after removing duplicates: ", wordsToUpdate);
    sendSkippedWordsToBacked(wordsToUpdate);
  }

  function sendSkippedWordsToBacked(wordsToUpdate) {
    if (wordsToUpdate.length === 0)
      return console.log("No skipped words to update in the backend.");

    // Extracting only the needed properties from each word object (otherwise the payload would be too big)
    const wordsToSend = wordsToUpdate.map(({ word, strength }) => ({
      word,
      strength,
    }));

    console.log(
      "Sending these skipped words to backend for updating: ",
      wordsToSend
    );

    // send a put request to the api to update the word
    const request = {
      words: wordsToSend,
    };
    axios
      .put("/api/user/vocabulary/add-skipped-words", request)
      .then((response) => {
        console.log(
          "RESPONSE FROM UPDATE SKIPPED WORDS backend: ",
          response.data
        );
        // Assuming `setKnownWordsArray` and `knownWordsArray` are defined elsewhere
        setKnownWordsArray(knownWordsArray.concat(response.data.addedWords));
        console.log("Updated known words array with skipped words.");
      })
      .catch((err) => {
        console.error("ERROR WHEN UPDATING SKIPPED WORDS in backend:", err);
      });
  }

  function sendWordsToUpdateToBackend(wordsToUpdate) {
    if (wordsToUpdate.length === 0)
      return console.log("No words to update in the backend.");
    console.log("Sending these words to backend for updating: ", wordsToUpdate);
    // send a put request to the api to update the word
    const request = {
      words: wordsToUpdate,
    };
    axios
      .put("/api/user/update-words", request)
      .then((response) => {
        // setKnownWordsArray(knownWordsArray.concat([response.data.added]));
        // console.log("Added new known word: ", newWord);
        console.log("RESPONSE FROM UPDATE WORDS backend: ", response.data);
      })
      .catch((err) => {
        console.error("ERROR WHEN UPDATING WORDS in backend:", err);
      });
  }

  function insertWords(startingIndex) {
    console.log("Starting to insert words...");
    const textContentWordsDiv = document.getElementById("textContentWords");
    if (!textContentWordsDiv) {
      console.error("textContentWords div not found");
      return;
    }

    // Hide tooltip when inserting new words
    document.getElementById("tooltip").style.display = "none";

    textContentWordsDiv.innerHTML = "";

    let globalWordIndex = 0;

    messages.forEach((message, messageIndex) => {
      console.log(`Processing message ${messageIndex}:`, message);
      const messageDiv = document.createElement("div");
      messageDiv.className = `message ${
        message.sender === "user" ? "user-message" : "ai-message"
      }`;

      if (message.loading) {
        messageDiv.innerHTML =
          '<div class="spinner-grow" role="status"><span class="visually-hidden">Loading...</span></div>';
        textContentWordsDiv.appendChild(messageDiv);
        return;
      }

      // Create a container for the message content
      const messageContentDiv = document.createElement("div");
      messageContentDiv.className = "message-content";

      // Process and add the main message text
      const processedMessage = processMessages([message])[0];
      processedMessage.words.forEach((word, i) => {
        console.log(`Creating word element for: "${word.word}"`, word);
        const wordDiv = document.createElement("span");
        wordDiv.textContent = word.word;
        wordDiv.className = "word";
        wordDiv.dataset.index = globalWordIndex++; // Use the global index
        wordDiv.dataset.messageId = message.id;
        wordDiv.dataset.sentence = message.text;

        // Add all word properties to dataset for debugging
        Object.entries(word).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            wordDiv.dataset[key] = value;
          }
        });

        if (word.known) {
          if (word.translation) {
            wordDiv.classList.add("known");
            wordDiv.classList.add(`known-strength-${word.strength}`);
          } else {
            wordDiv.classList.add("skipped");
          }
          wordDiv.addEventListener("click", handleWordClick);
        } else if (word.is_not_a_word) {
          wordDiv.classList.add("punctuation");
          if (word.is_number) {
            wordDiv.classList.add("number");
            wordDiv.addEventListener("click", handleWordClick);
          }
        } else {
          wordDiv.classList.add("unknown");
          wordDiv.addEventListener("click", handleWordClick);
        }

        messageContentDiv.appendChild(wordDiv);
      });

      // Add correction if it exists (for user messages)
      if (message.correction) {
        const correctionDiv = document.createElement("div");
        correctionDiv.className = "message-correction";

        const processMarkdownText = (text) => {
          const container = document.createElement("div");
          const correctionTokens = []; // Separate array for correction tokens

          // Split the text by bold markdown pattern only
          const parts = text.split(/(\*\*.*?\*\*)/g);
          let correctionIndex = 0; // Separate index for corrections

          parts.forEach((part) => {
            const isBold = /^\*\*(.*?)\*\*$/.test(part);
            const cleanText = part.replace(/\*\*/g, "");

            if (!cleanText) return;

            const tokens = processText(cleanText);
            const processedWords = createWords(
              tokens,
              knownWordsArray || [],
              "body"
            );

            processedWords.forEach((word) => {
              // Add to correction tokens array instead of global tokens
              correctionTokens.push({
                ...word,
                sentence: text,
              });

              const wordDiv = document.createElement("span");
              wordDiv.textContent = word.word;
              wordDiv.className = "word";
              wordDiv.dataset.index = correctionIndex++; // Use correction-specific index
              wordDiv.dataset.messageId = `correction-${message.id}`;
              wordDiv.dataset.sentence = text;
              wordDiv.dataset.isCorrection = "true"; // Mark as correction word

              if (isBold) {
                wordDiv.style.fontWeight = "bold";
              }

              // Create custom click handler for correction words
              wordDiv.addEventListener("click", (event) => {
                const clickedIndex = parseInt(event.target.dataset.index);
                const clickedWord = correctionTokens[clickedIndex];

                // Add highlighting
                event.target.classList.add("highlighted");

                // Create word data object for the translation panel
                const wordData = {
                  _id: clickedWord._id || null,
                  word: clickedWord.word,
                  sentence: clickedWord.sentence,
                  known: clickedWord.known || false,
                  translation: clickedWord.translation,
                  strength: clickedWord.strength || 0,
                  is_not_a_word: clickedWord.is_not_a_word || false,
                  is_number: clickedWord.is_number || false,
                  srs_due_date: clickedWord.srs_due_date,
                  date_added: clickedWord.date_added,
                };

                setSelectedWord(wordData);
              });

              // Add word classification classes
              if (word.known) {
                if (word.translation) {
                  wordDiv.classList.add("known");
                  wordDiv.classList.add(`known-strength-${word.strength}`);
                } else {
                  wordDiv.classList.add("skipped");
                }
              } else if (word.is_not_a_word) {
                wordDiv.classList.add("punctuation");
                if (word.is_number) {
                  wordDiv.classList.add("number");
                }
              } else {
                wordDiv.classList.add("unknown");
              }

              container.appendChild(wordDiv);
            });
          });

          return container;
        };

        const formattedCorrection = processMarkdownText(message.correction);
        correctionDiv.appendChild(formattedCorrection);
        messageContentDiv.appendChild(correctionDiv);
      }

      messageDiv.appendChild(messageContentDiv);
      textContentWordsDiv.appendChild(messageDiv);
    });
  }

  function clearTranslationPanelSelectedWord() {
    setSelectedWordOriginalTextState({});
    setWordForFullLookup(null);
    setWordForQuickLookup(null);
    const highlightedWords = document.querySelectorAll(".highlighted");
    highlightedWords.forEach((word) => {
      word.classList.remove("highlighted");
    });
    // hide the tooltip
    console.log("Translation panel cleared.");
    document.getElementById("tooltip").style.display = "none";
    // this is for mobile only essentially to make sure the content is visible again after the translation panel was opened
    document.getElementById("textContent").style.display = "block";
  }

  function addUnknownWordToKnownWords(word, translation, strength) {
    let newWord = {
      word: word,
      translation: translation,
      strength: strength,
    };
    console.log("Sending this word to backend for updating: ", newWord);
    context.saveNewWordToBackend(newWord); // TODO: should contain current
    return;
  }

  function updateKnownWordInKnownWords(wordToUpdate, translation, strength) {
    console.log("checking updated known word: ", wordToUpdate);
    console.log("the strength of the updated word should be: ", strength);
    // NOTE: the strength for the word that was skipped is 4, it's not updated here to 0 when meaning is added
    // find the index of the word to update
    const index = knownWordsArray.findIndex(
      (knownWord) => knownWord._id === wordToUpdate._id
    );
    // update the word in the array
    let newWord = knownWordsArray[index];
    // let the user update a translation only if the word already had a translation (and wasn't therefore skipped)
    // if (newWord.translation === undefined && checkIfFreePlanLimitReached())
    //   return;
    newWord.translation = translation;
    // NOTE: existing strength could also be 0
    newWord.strength =
      wordToUpdate.strength !== undefined && wordToUpdate.strength !== null
        ? wordToUpdate.strength
        : strength;
    if (newWord.strength !== strength) newWord.strength = strength;
    // set the state variable to the new array without referencing the old one
    setKnownWordsArray([...knownWordsArray]);

    console.log("Updated known word: ", newWord);
    sendWordsToUpdateToBackend([newWord]);
  }

  // Function to process messages into tokens
  function processMessages(messages) {
    console.log("processText: Processing messages:", messages);
    return messages.map((message) => {
      console.log(`Processing message: ${message.text}`);
      const words = processText(message.text);
      console.log("Raw tokens:", words);
      const processedWords = createWords(words, knownWordsArray || [], "body");
      console.log("Processed words with known/unknown status:", processedWords);
      return {
        ...message,
        words: processedWords,
      };
    });
  }

  const createChatThread = async (languageLearning, languageBase) => {
    // Extract articleId from URL parameters
    const params = new URLSearchParams(location.search);
    const article_id = params.get("articleId");
    try {
      const response = await axios.post("/api/chat/create-chat-thread", {
        language_learning_string: languageLearning,
        language_base_string: languageBase,
        articleId: article_id,
      });

      console.log("Chat thread created with ID:", response.data.threadId);
      setCurrentThreadId(response.data.threadId); // Store the thread ID

      // Extract articleId from URL parameters
      const params = new URLSearchParams(location.search);
      const articleId = params.get("articleId");

      // Send the article_id as a message to the newly created thread
      if (articleId) {
        await sendArticleIdAsMessage(response.data.threadId, articleId);
      }
    } catch (error) {
      console.error("Error creating chat thread:", error);
    }
  };

  const sendArticleIdAsMessage = async (threadId, articleId) => {
    try {
      setLoadingAIResponse(true);
      const response = await axios.post("/api/chat/send-message", {
        threadId: threadId,
        message: "",
        articleId: articleId,
      });

      // Parse the response data if it's a string
      const replyData =
        typeof response.data.reply === "string"
          ? JSON.parse(response.data.reply)
          : response.data.reply;

      const aiResponse = {
        text: replyData.answer || replyData, // Use answer property if available, fallback to entire reply
        sender: "ai",
        id: Date.now(),
      };
      console.log("Adding AI response for article ID:", aiResponse);
      setMessages((prev) => [...prev, aiResponse]);
    } catch (error) {
      console.error("Error sending article ID as message:", error);
    } finally {
      setLoadingAIResponse(false);
    }
  };

  useEffect(() => {
    // Find the active language pair codes
    const selectedLanguagePair = context.language_pairs.find(
      (pair) => pair.is_selected
    );

    createChatThread(
      selectedLanguagePair.language_learning.name,
      selectedLanguagePair.language_base.name
    );
  }, [location, context.language_pairs]);

  // Define an array of tips
  const tips = [t("chat:tips_1"), t("chat:tips_2"), t("chat:tips_3")];

  // State to store the selected tip
  const [selectedTip, setSelectedTip] = useState("");

  // Function to get a random tip
  const getRandomTip = () => {
    const randomIndex = Math.floor(Math.random() * tips.length);
    return tips[randomIndex];
  };

  // Set the tip when the component mounts
  useEffect(() => {
    setSelectedTip(getRandomTip());
  }, []);

  const openAccountPage = () => {
    navigate("/account"); // Navigate to the account page
  };

  return (
    <>
      <Container id="readerContainer" fluid="md" translate="no">
        <Row style={{ minHeight: "100svh" }}>
          <Col xs={12} md={8} lg={8} xl={9} className="d-flex flex-column">
            {/* Sticky Top Menu */}
            <Row className="justify-content-between align-items-start pt-3 sticky-top-row">
              <Col xs={2} md={1} className="d-flex justify-content-center">
                <Link to="/library">
                  <Button
                    id="readerCloseReaderButton"
                    variant="light"
                    size="sm"
                  >
                    &#10006;
                  </Button>
                </Link>
              </Col>
              <Col xs={8} md={10} className="text-center">
                {/* Add "Chat" with "beta" label */}
                <span>
                  {t("chat:chat_title")}{" "}
                  <sup>
                    <span className="badge bg-warning text-dark">
                      {t("common:beta")}
                    </span>
                  </sup>
                </span>
              </Col>
              <Col xs={2} md={1} className="d-flex justify-content-center">
                <Dropdown align="end" autoClose="outside">
                  <Dropdown.Toggle
                    as="button"
                    variant="link"
                    className="p-0 border-0 bg-transparent no-caret"
                  >
                    <ThreeDots size={20} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Header>{t("chat:chat_settings")}</Dropdown.Header>
                    <Dropdown.Item
                      onClick={openAccountPage}
                      className="d-flex align-items-center"
                    >
                      {t("chat:setting_see_and_edit_your_interests")}{" "}
                      <BoxArrowInRight className="ms-2" />
                    </Dropdown.Item>
                    <Dropdown.Item className="d-flex align-items-center">
                      <Form>
                        <Form.Check
                          type="switch"
                          id="quick-lookup-switch"
                          checked={quickLookupRef.current}
                          onChange={() => {
                            context.toggleQuickLookupInChat();
                            quickLookupRef.current = !quickLookupRef.current;
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Form>
                      <div className="fs-6">
                        {t("chat:setting_quick_word_lookup_in_chat")}
                      </div>
                    </Dropdown.Item>

                    <Dropdown.Header>
                      {t("readers:global_settings")}
                    </Dropdown.Header>
                    <Dropdown.Item className="d-flex align-items-center">
                      <Form>
                        <Form.Check
                          type="switch"
                          id="ai-explanation-switch"
                          checked={expandAIExplanationBoxRef.current}
                          onChange={() => {
                            context.toggleExpandAIExplanationBox();
                            expandAIExplanationBoxRef.current =
                              !expandAIExplanationBoxRef.current;
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Form>
                      <div className="fs-6">
                        {t(
                          "readers:global_setting_expand_ai_explanation_box_by_default"
                        )}
                      </div>
                    </Dropdown.Item>

                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={openSupportCenter}
                      className="d-flex align-items-center"
                    >
                      <InfoCircle className="me-2" />{" "}
                      {t("readers:global_setting_support_center")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={sendBugReport}
                      className="d-flex align-items-center"
                    >
                      <Bug className="me-2" />{" "}
                      {t("readers:global_setting_send_bugs_and_feedback")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            {/* Main content area */}
            <Row className="flex-grow-1">
              <Col
                id="textContent"
                onTouchStart={handleTouchStart}
                onContextMenu={handleContextMenu}
                className="flex-grow-1 overflow-auto pb-5"
              >
                <div className="d-flex flex-column" id="textContentFlexColumn">
                  {messages.length === 0 && !articleId && (
                    <div className="text-center text-muted">
                      <p>{selectedTip}</p> {/* Display the selected tip */}
                    </div>
                  )}
                  <div id="textContentWords"></div>
                  <div ref={messagesEndRef} />
                  {loadingAIResponse && (
                    <Spinner animation="grow" size="sm" className="mt-3 ms-3" />
                  )}
                </div>
              </Col>
            </Row>

            {/* Fixed input area at bottom */}
            <Row className="sticky-bottom-row bg-white py-3">
              <Col>
                <Form className="d-flex" onSubmit={handleSendMessage}>
                  <Form.Control
                    ref={inputRef}
                    type="text"
                    placeholder={t("chat:placeholder_type_your_message")}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className="me-2"
                    disabled={loadingAIResponse}
                    maxLength={500}
                  />
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={loadingAIResponse}
                  >
                    <span className="d-none d-sm-inline">{t("chat:send")}</span>
                    <Send className="d-sm-none" />
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>

          {/* Translation panel column */}
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={3}
            id="translations"
            tabIndex="0"
            className={isFullScreen ? "full-screen" : ""}
            onTouchStart={handleTouchStartTranslationsPanel}
            onTouchMove={handleTouchMoveTranslationsPanel}
          >
            <Button
              variant="light"
              onClick={closeTranslationsPanel}
              id="closeTranslationsPanelButton"
              className={
                isFullScreen ? "closeTranslationsPanelButtonBigger" : ""
              }
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              &#x2715;
            </Button>
            <TranslationPanel
              selectedWord={wordForFullLookup}
              setSelectedWord={setWordForFullLookup}
              refreshPage={refreshPage}
              addUnknownWordToKnownWords={addUnknownWordToKnownWords}
              updateKnownWordInKnownWords={updateKnownWordInKnownWords}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              closeTranslationsPanel={closeTranslationsPanel}
              fullTextTokens={tokens}
              clearTranslationPanelSelectedWord={
                clearTranslationPanelSelectedWord
              }
              speakOnRender={false}
            />
          </Col>
        </Row>
      </Container>

      <QuickTranslationTooltip
        selectedWord={wordForQuickLookup}
        setSelectedWord={setWordForQuickLookup}
        setWordForFullLookup={setWordForFullLookup}
        addUnknownWordToKnownWords={addUnknownWordToKnownWords}
        updateKnownWordInKnownWords={updateKnownWordInKnownWords}
        speakOnRender={false}
      />
    </>
  );
}

function createWords(wordsArray, knownWords, inputType) {
  let words = [];
  for (let i = 0; i < wordsArray.length; i++) {
    let word =
      wordsArray[i].word !== undefined
        ? {
            word: wordsArray[i].word,
            sentence: escapeHTML(wordsArray[i].sentence),
          }
        : {
            word: wordsArray[i].token,
            sentence: escapeHTML(wordsArray[i].sentence),
          };

    word.known = false;
    word.is_not_a_word = false;
    word.is_number = false;
    if (inputType === "title") {
      word.is_title = true;
      if (i === wordsArray.length - 1) {
        word.is_last_title_word = true;
      }
    } else {
      word.is_title = false;
    }

    const regex = /[\p{P}\p{Z}\p{N}]/gu;
    const got_punctuation = word.word.match(regex);
    const is_a_phrase = /(?<=\S)\s(?=\S)/.test(word.word);

    if (got_punctuation && !is_a_phrase) {
      word.is_not_a_word = true;
      word.known = undefined;
    } else {
      const known = checkIfWordIsKnown(word.word, knownWords);
      if (known) {
        word.known = true;
        word._id = known._id;
        word.translation = known.translation;
        word.strength = known.strength;
        word.srs_due_date = known.srs_due_date;
      }
    }

    const regexNumber = /[\d]+([.,\s]?[\d]+)*/g;
    const is_number = word.word.match(regexNumber);
    if (is_number) {
      word.is_number = true;
    }
    words.push(word);
  }
  return words;
}

const trackSongListened = async (songId, location, language_learning_code) => {
  try {
    const response = await axios.post(`/api/user/songs/${songId}`, {
      location: location,
      language_learning_code: language_learning_code,
    });

    console.log(response.data.message);
  } catch (error) {
    console.error("Error tracking song listened:", error);
  }
};

export default App;
